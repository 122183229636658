import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import ASK from '../../Images/ASK (3).gif';

function Index() {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <div className={`loader-effect theme-switcher ${theme === "dark" ? "loader-color" : "light-theme"}`}>
          <img src={ASK} alt="" style={{ width: "100px" }} />
        </div>
      </Backdrop>
    </div>
  );
}

export default Index;
