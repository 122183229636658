import axiosInstance from '../axiosInstance';


export const photoUpload = async (data) => {
  console.log("photoUpload-data", data)
  try {
    const response = await fetch(`${process.env.REACT_APP_WEB_BASE_URL}/user/upload/fileUpload`, {
      method: 'POST',
      body: data,
    });
    const responseText = await response.text(); // Get raw response text for debugging
    console.log(responseText); // Log the response text
    if (!response.ok) {
      throw new Error('Network response was not ok: ' + responseText);
    }
    return JSON.parse(responseText); // Parse JSON from text response
  } catch (error) {
    console.error("Error in photoUpload:", error);
    throw error;
  }
}

export const videoUpload = async (data) => {
  console.log("videoUpload-data", data)
  try {
    const response = await fetch(`${process.env.REACT_APP_WEB_BASE_URL}/user/videoIdentification/videoUpload`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      },
      body: data,
    });
    const responseText = await response.text(); // Get raw response text for debugging
    console.log(responseText); // Log the response text
    if (!response.ok) {
      throw new Error('Network response was not ok: ' + responseText);
    }
    return JSON.parse(responseText); // Parse JSON from text response
  } catch (error) {
    console.error("Error in videoUpload:", error);
    throw error;
  }
}



// // Example of API call using fetch
// fetch(`${process.env.REACT_APP_WEB_BASE_URL}/user/videoIdentification/videoUpload`, {
//   method: 'POST',
//   headers: {
//     "Authorization": `Bearer ${localStorage.getItem("token")}`
//   },
//   body: formData,
// })
//   .then(response => response.json())
//   .then(data => {
//     console.log('Video uploaded successfully:', data);
//     setUploading(false); // Reset uploading state
//   })
//   .catch(error => {
//     console.error('Error uploading video:', error);
//     setUploading(false); // Reset uploading state on error
//   });

export const profileComplete = async (data) => {
  console.log(data, "userDatauserDatauserData");

  try {
    console.log(data, "userDatauserDatauserData");
    const response = await fetch(`${process.env.REACT_APP_WEB_BASE_URL}/user/updateProfile`, {
      method: 'PUT',
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      },
      body: data,
    });

    console.log(response, "responseresponseresponseresponse");
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const getMyProfile = async () => {
  try {
    const response = await axiosInstance.get('/user/myProfile');
    console.log(response.data, "myProfileData");
    return response.data;
  } catch (error) {
    console.error("Error in getMyProfile:", error);
    throw error;
  }
};

export const changePassword = async (data, token) => {
  try {
    console.log(data, "userDatauserDatauserData");

    const response = await axiosInstance.put('/user/changePassword', data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    console.log(response, "changePassword");

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const changeEmail = async (data, token) => {
  try {
    const response = await axiosInstance.put('/user/changeEmail', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};