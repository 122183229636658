import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import logo from '../Images/logo-mini.png';
import NotificationsIcon from '../Images/NotificationsIcon.png';
import { getMyProfile } from '../services/api/profileApi';
import Loaders from '../component/Loaders';
import { getMessageId, getMyNotifications } from '../services';
import { notifyError } from '../toastNotifications';
import Icon from '../Images/circle-dot-solid.png';
import NoMore from '../Images/no-da.svg';
import CircularProgress from '@mui/material/CircularProgress';
import { formatDateAndTime } from '../utils/dateFormatter';
import DarkTheme from '../component/DarkTheme';
import LogoutProfile from '../component/Profile/Logout/LogoutProfile';
import { socketMessageContext,socket } from '../context/SocketMessageContext';
import { Margin } from '@mui/icons-material';

const pages = [
  // { label: 'Home', path: '/home' },
  { label: 'Ask', path: '/search-note' },
  { label: 'Inbox', path: '/inbox-message' },
  { label: 'About Us', path: '/about-us' },
  { label: 'Contracts', path: '/contracts' },
  { label: 'Profile', path: '/profile' },
  // { component: <DarkTheme /> }
];

const settings = [
  // { label: 'Profile', path: '/profile' },
  // { label: 'Contact Us', path: '/contact' },
  // { component: <LogoutProfile /> },
  // { component: <DarkTheme /> }

];

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { unreadAlerts, setUnreadAlerts } = useContext(socketMessageContext);

  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notificationsData, setNotificationsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  const dropdownRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMyNotifications = async () => {
    let data = {
      offset: 1,
      limit: 1000
    };
    setIsLoading(true);
    setNotificationsData([]);
    try {
      const response = await getMyNotifications(data);
      if (response.statusCode === 200) {
        setNotificationsData(response?.data?.notifications || []);
      }
    } catch (error) {
      setNotificationsData([]);
      notifyError(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const getMessageIdHandler = async (id) => {
    console.log("id------", id)
    try {
      const response = await getMessageId(id);
      console.log("response ", response);
      if (response.statusCode === 200) {
        return response.data.messageID
      }
    } catch (error) {
      console.log("error", error)
      return null
    }
  }

  const toggleDropdown = async (
    id,
    status,
    notesId,
    messageId,
    message,
    crDate,
    title
  ) => {
    setUnreadAlerts(0);
    console.log("rdf", id)
    setShowDropdown(!showDropdown);
    if (!showDropdown) {
      handleMyNotifications();
    } else {
      if (id) {
        // let data = {
        //   notesId,
        //   messageId,
        //   notificationId: id,
        // }
        // localStorage.setItem("notification", JSON.stringify(data))

        // navigate(`/accept-reject/${messageId}`);

        let messsageIdForUse = messageId && messageId !== null ? messageId : await getMessageIdHandler(id);
        console.log("messageIdForUse", messsageIdForUse)

        if (status === '1') {
          let data = {
            notesId,
            messageId: messsageIdForUse,
            notificationId: id,
          }
          localStorage.setItem("notification", JSON.stringify(data))
          navigate(`/accept-reject/${messsageIdForUse}`);
        } else if (status === '26') {
          console.log("title", title)
          if (title && title.toLowerCase() === `contract started`) {
            navigate(`/message-send/${messsageIdForUse}`);
          } else {
            navigate(`/accept-reject/${messsageIdForUse}`);
          }
        } else if (status === '3') {
          navigate(`/message-send/${messsageIdForUse}`);
        } else {
          handleMyNotifications();
        }
      }
    }
  };

  const handlePutOnLocalStorage = (obj) => {
    let localObj = JSON.parse(localStorage.getItem('user'));
    for (let key in obj) {
      localObj[`${key}`] = obj[key];
    }
    localStorage.setItem('user', JSON.stringify(localObj));
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getMyProfile();
        handlePutOnLocalStorage(data.data.user);
        setProfile(data.data.user);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchProfile();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);


  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);
                      
  useEffect(() => {
    if (socket) {
      if (JSON.parse(localStorage.getItem('user'))._id) {
        socket.emit('getUserRealtimeMessages', { userId: JSON.parse(localStorage.getItem('user'))._id });
        socket.on('getUserRealtimeMessages', (msg) => {
          // console.log("Received message Ib NAVBAR JS--------------------->>>>>>>>>>>>>>>>>>>>:", msg);
          setUnreadAlerts(msg?.unreadCount)
          if (msg && Object.keys(msg).length == 0) {
            socket.emit('getUserRealtimeMessages', { userId: JSON.parse(localStorage.getItem('user'))._id });
            socket.on('getUserRealtimeMessages', (msg) => {
              setUnreadAlerts(msg?.unreadCount)
            });
          }
        });
      }
    }
  })

  if (loading) {
    return <Loaders />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const ReloadWeb = () => {
    window.location.reload(); // Reload the website
  };


  return (
    <div className="container-fluid m-0 p-0 pb-5">
      <AppBar position="static custom-header-compare " className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
        <Container maxWidth="xl" >
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component={NavLink}
              to="/home"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 800,
                letterSpacing: '.3rem',
                textDecoration: 'none',
              }}
              style={{ color: 'black' }}

            >
              <img src={logo} alt="" className="logo-nav p-2" />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.label} onClick={handleCloseNavMenu} className={`m-3 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
                    <NavLink
                      to={page.path}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      className={`m-3 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                    >
                      {page.label}
                    </NavLink>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/home"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                textDecoration: 'none',
              }}
              style={{ color: 'black' }}
            >
              <img src={logo} alt="" className="logo-nav p-2" />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className="ps-5">
              
              {pages.map((page) => (
                <Button
                  key={page.label}
                  component={NavLink}
                  to={page.path}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: 'black',
                    display: 'flex',
                    borderBottom: location.pathname === page.path ? '2px solid #A50C93' : 'none',
                  }}
                  // className="nav-typography"
                  className={`nav-typography theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                >
                  {page.label}
                </Button>
              ))}
            </Box>
            <Box sx={{ flexGrow: 0 , display: { xs: 'flex', md: 'flex' }   }}>
            <div className="mt-2 pt-1 ms-4 ps-3" sx={{ Margin: { xs: '0' }   }}><DarkTheme /></div>
              <IconButton size="large" aria-label="show 17 new notifications" className='notifications-class' color="inherit" onClick={() => toggleDropdown()}>
                <div className="mx-4">
                  <Badge color="error" badgeContent={unreadAlerts > 9 ? `9+` : unreadAlerts} className='notifaction-icon'>
                    <img src={NotificationsIcon} alt="" />
                  </Badge>
                </div>
              </IconButton>
              {showDropdown && (
                <div ref={dropdownRef} style={styles.dropdown} className={` 'inner-shadow notification-list mt-3 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
                  {isLoading
                    ? <div className='h-100 d-flex justify-content-center align-items-center'>
                      <CircularProgress />
                    </div>
                    : notificationsData && notificationsData.length > 0 ? (
                      <>
                        {notificationsData.map((val, index) => {
                          let id = val?._id || null;
                          let status = val?.status || null;
                          let notesId = val?.notesId?._id || null;
                          let messageId = val?.messageId || null;
                          let message = val?.message || null;
                          let crDate = val?.createdAt || new Date();
                          let title = val?.title || null;
                          return (
                            // <div key={index} className={` ' notifaction-shadow mt-3 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`} onClick={() => toggleDropdown(messageId, id, notesId)}>
                            <div key={index} className={` ' notifaction-shadow mt-3 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                              onClick={() => toggleDropdown(
                                id,
                                status,
                                notesId,
                                messageId,
                                message,
                                crDate,
                                title
                              )}>
                              <p className="radio-message-new">
                                <img src={Icon} alt="" className='radio-message' />
                                <label>{formatDateAndTime(crDate)}</label>
                              </p>
                              <p className='mt-2'>{message}</p>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <img src={NoMore} alt="" className="no-da mb-4" />
                    )}
                </div>
              )}
        
                <IconButton sx={{ p: 0 } } className='mt-3'
                // onClick={handleOpenUserMenu}
                >
                  <Link to="/profile"><Avatar alt="Profile Image" src={profile.profileImage} className='notifaction-icon' /></Link>
                </IconButton>
             
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting, index) => (
                  <MenuItem key={index} onClick={handleCloseUserMenu} className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
                    {setting.path ? (
                      <Link to={setting.path} style={{ textDecoration: 'none', color: 'inherit', width: "100%" }}>
                        <Typography textAlign="left" style={{ width: "100%" }}>{setting.label}</Typography>
                      </Link>
                    ) : (
                      setting.component
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <br />
    </div>
  );
}

const styles = {
  dropdown: {
    position: 'absolute',
    right: 0,
    top: '100%',
    backgroundColor: 'white',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    zIndex: 1000,
    width: '450px',
  },
};

export default Navbar;