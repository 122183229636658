import React from 'react'
import Image from '../../../Images/10782771_19197307.jpg';
import Footer from "../../Footer";
import image from '../../../Images/Requirements-for-Youtube-Channel-Monetization-1024x512-1-150x150_c.jpg';
import { Link } from 'react-router-dom';

function Blog() {
  return (
    <div>
        <div className="container-fluid mein-header-blog-video pt-5 pb-5">
            

<h1 className='mt-5'>Latest Topics</h1>

        </div>
        <div className="container">
          <Link to="/blog-details" style={{textDecoration:"none", color:"black"}}>
          <div className="row mt-5 mb-5 border-1 p-3 blog-border">
            <div className="col-lg-2">
              <br />
              <span className='span-blog mt-2'>07-08-2024</span>
              <p className='pt-4'>in Digital Marketing Company, Youtube Marketing</p>
            </div>
            <div className="col-lg-3">
              <img src={image} alt="" />
            </div>
            <div className="col-lg-7">
              <p><b>Requirement for YouTube channel monetization</b></p>
              <p>Requirement for YouTube channel monetization The first step is to monetizing your YouTube videos is by becoming a member of the YouTube partner program. While anyone can create a YouTube account, However, the requirement for YouTube channel monetization is that you have to meet some basic criteria to become a member of YouTube partner program,...</p>
            </div>
          </div></Link>
        </div>
        <Footer />
    </div>
  )
}

export default Blog