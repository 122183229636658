import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import NoMore from '../../../Images/no-da.svg'
import {
  endDispute,
  getDisputesList,
  getMyDisputesList,
} from "../../../services";
import { notifyError, notifySuccess } from "../../../toastNotifications";
import { formatDate } from "../../../utils/dateFormatter";
import Loaders from '../../Loaders/index'




<img src={NoMore} alt="" className="no-da mb-4" />

export default function LabTabs() {
  const [value, setValue] = React.useState("1");
  const [disputesData, setDisputesData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [loading, setLoading] = useState(true);

  const handleGetMyDisputesList = async () => {
    try {
      const response = await getMyDisputesList(); // Assuming your API supports pagination
      console.log("getMyDisputesList response is here -->", response);
      if (response.statusCode === 200) {
        setDisputesData((prevData) => [
          ...prevData,
          ...(response?.data?.disputeList || []),
        ]);
        if (response?.data?.disputeList?.length === 0) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error in getMyDisputesList :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    }
    finally {
      setLoading(false); // Set loading to false when data fetching is complete
    }
  };

  const handleGetDisputesList = async () => {
    try {
      const response = await getDisputesList(); // Assuming your API supports pagination
      console.log("getDisputesList response is here -->", response);
      if (response.statusCode === 200) {
        setDisputesData((prevData) => [
          ...prevData,
          ...(response?.data?.disputeList || []),
        ]);
        if (response?.data?.disputeList?.length === 0) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error in getDisputesList :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    }
    
  };

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  React.useEffect(() => {
    setDisputesData([]);
    setPage(1);
    setHasMore(true);
    if (value === "1") {
      handleGetMyDisputesList();
    } else if (value === "2") {
      handleGetDisputesList();
    }
  }, [value]);

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
    if (value === "1") {
      handleGetMyDisputesList();
    } else if (value === "2") {
      handleGetDisputesList();
    }
  };

  // const handleEndDispute = async (msgId) => {
  //   let data = { messageId: msgId };
  //   try {
  //     const response = await endDispute(data);
  //     console.log("endDispute response is here -->", response);
  //     if (response.statusCode === 200) {
  //       notifySuccess(response.message);
  //       setDisputesData([]);
  //       setPage(1);
  //       setHasMore(true);
  //       handleGetMyDisputesList();
  //     }
  //   } catch (error) {
  //     console.error("Error in endDispute :", error);
  //     notifyError(error?.response?.data?.message || `Something went wrong`);
  //   }
  // };
  if (loading) {
    return <Loaders />; // Render the loader while loading is true
  }

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{}}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label="My Disputes"
              value="1"
              className={` ' contracts-nav-heading theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
            />
            <Tab label="Disputes" value="2" className={` ' contracts-nav-heading theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`} />
          </TabList>
        </Box>
        <TabPanel value="1">
          <InfiniteScroll
            dataLength={disputesData.length}
            next={fetchMoreData}
            hasMore={false}
            loader={<h6>Loading...</h6>}
            endMessage={
              <></>
              // <p style={{ textAlign: "center" }}>No more disputes</p>
            }
          >
            <div className="row all-dispute-list">
              {disputesData && disputesData.length > 0 ? (
                disputesData.map((val, index) => {
                  let contractId = val?.message?.contractId || null;
                  let msgId = val?.message?._id || null;
                  let time = val?.createdAt || new Date();
                  let reason = val?.reasons.reason || null;
                  let status =
                    val?.isStatus === 0
                      ? `Dispute resolved`
                      : val?.isStatus === 1
                        ? `Pending`
                        : null;
                  let colorcls =
                    val?.isStatus === 0
                      ? `is-resolved` 
                      : val?.isStatus === 1
                        ? `in-review`
                        : "";
                  return (
                    <div className="col-md-6 mt-3" key={index}>
                      <div className="my-dispute-card text-start p-3">
                        <div className="row">
                          <div className="col-4">
                            <p className="text-dark">
                              <b className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`} >Contract Id</b>
                            </p>
                          </div>
                          <div className="col-8">
                            <p>{`#${contractId}`}</p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-4">
                            <p className="text-dark">
                              <b className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Dispute Date</b>
                            </p>
                          </div>
                          <div className="col-8">
                            <p>{formatDate(time)}</p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-4">
                            <p className="text-dark">
                              <b className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Reason</b>
                            </p>
                          </div>
                          <div className="col-8">
                            <p>{reason}</p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-4">
                            <p className="text-dark">
                              <b className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Status</b>
                            </p>
                          </div>
                          <div className="col-8">
                            <span className={colorcls}>{status}</span>
                          </div>
                        </div>
                        {/* {status && status === "Pending" ? (
                          <div className="row d-flex justify-content-end mt-2">
                            <div className="col-6">
                              <button
                                className="search-btn w-100 mt-3"
                                onClick={() => handleEndDispute(msgId)}
                              >
                                End Dispute
                              </button>
                            </div>
                          </div>
                        ) : null} */}
                      </div>
                    </div>
                  );
                })
              ) : (
                <img src={NoMore} alt="" className="no-da mb-4" />
              )}
            </div>
          </InfiniteScroll>
        </TabPanel>
        <TabPanel value="2">
          <InfiniteScroll
            dataLength={disputesData.length}
            next={fetchMoreData}
            hasMore={false}
            loader={<h2>Loading...</h2>}
            endMessage={<p style={{ textAlign: "center" }}>No more disputes</p>}
          >
            <div className="row all-dispute-list">
              {disputesData && disputesData.length > 0 ? (
                disputesData.map((val, index) => {
                  let contractId = val?.message?.contractId || null;
                  let time = val?.createdAt || new Date();
                  let reason = val?.reasons.reason || null;
                  let status =
                    val?.isStatus === 0
                      ? `Resolved`
                      : val?.isStatus === 1
                        ? `Pending`
                        : null;
                  return (
                    <div className="col-md-6 mt-3" key={index}>
                      <div className="my-dispute-card text-start p-3">
                        <div className="row">
                          <div className="col-4">
                            <p className="text-dark">
                              <b className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Contract Id</b>
                            </p>
                          </div>
                          <div className="col-8">
                          <p>{`#${contractId}`}</p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-4">
                            <p className="text-dark">
                              <b className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Dispute Date</b>
                            </p>
                          </div>
                          <div className="col-8">
                            <p>{formatDate(time)}</p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-4">
                            <p className="text-dark">
                              <b className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Reason</b>
                            </p>
                          </div>
                          <div className="col-8">
                            <p>{reason}</p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-4">
                            <p className="text-dark">
                              <b className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Status</b>
                            </p>
                          </div>
                          <div className="col-8">
                            <span className="in-review">{status}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <img src={NoMore} alt="" className="no-da mb-4" />
              )}
            </div>
          </InfiniteScroll>
        </TabPanel>
      </TabContext>
    </Box>
  );
}
