import React from 'react'

function ReferButton() {
  return (
    <div>
      <button className="Refer-btn m-5" >Refer</button>
    </div>
  )
}

export default ReferButton