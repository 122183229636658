import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { notifyError, notifySuccess } from '../../toastNotifications';

const Subscribe = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  const emailRegex = /^[^\s@]+@[^\s@]+\.(?:com|in|net|org|edu|gov|co\.uk)$/;

  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    if (!email || email.trim() === "") {
      notifyError(`Email required`)
    } else if (!emailRegex.test(email)) {
      notifyError(`Invalid email`)
    } else {
      notifySuccess("Thank you for your submission");
      setEmail("");
    }
  }


  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div id="faq" className=' pt-5'>
      <div className="container subscribe-section mt-5 mb-5 p-5">
        <h2 className='text-center pt-4'>Subscribe Now for Special Features !</h2>
        <div className={`subscribe-search-section mt-5 m-auto theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          <input
            type="text"
            placeholder='Enter Your Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`m-3 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`} />
          <button
            onClick={handleSubmit}
            className='m-2'>Submit <FontAwesomeIcon icon={faArrowRight} className='blog-card-icon' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Subscribe