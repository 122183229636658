import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { getContractList, getMyContractList } from "../../../services";
import { notifyError, notifySuccess } from "../../../toastNotifications";
import NoMore from '../../../Images/no-da.svg'
import {
  faPhotoFilm,
  faEllipsisH,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem, Popover } from "@mui/material";
import Loaders from '../../Loaders/index'

export default function LabTabs() {
  const [value, setValue] = React.useState("1");
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(false);
  const [filterStatus, setFilterStatus] = useState("4");
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);


  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const openPopover = Boolean(popoverAnchorEl);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setData([]);
    setPage(1);
    setHasMore(false);
    setFilterStatus("4");
  };

  const handleFilterChange = (val) => {
    setData([]);
    setPage(1);
    setFilterStatus(val);
    handlePopoverClose();
  }

  const handleGetContractList = async () => {
    let requestData = {
      offset: page,
      limit: 10,
      filterStatus
    };
    try {
      const response = await getContractList(requestData);
      console.log("getContractList response is here -->", response);
      console.log("getContractList response is here -->", response?.data?.message);
      if (response.statusCode === 200) {
        setData((prevData) => [
          ...prevData,
          ...(response?.data?.message || []),
        ]);
        if (response?.data?.message?.length == 10) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error in getContractList :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    }
    finally {
      setLoading(false); // Set loading to false when data fetching is complete
    }
  };

  const handleGetMyContractList = async () => {
    let requestData = {
      offset: page,
      limit: 10,
      filterStatus
    };
    try {
      const response = await getMyContractList(requestData);
      console.log("getMyContractList response is here -->", response);
      console.log("getMyContractList response is here -->", response?.data?.message);
      if (response.statusCode === 200) {
        setData((prevData) => [
          ...prevData,
          ...(response?.data?.message || []),
        ]);
        if (response?.data?.message?.length == 10) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error in getMyContractList :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    }
  };


  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  React.useEffect(() => {
    if (value === "1") {
      handleGetContractList();
    } else if (value === "2") {
      handleGetMyContractList();
    }
  }, [value, page, filterStatus]);

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };
  if (loading) {
    return <Loaders />; // Render the loader while loading is true
  }

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{}}>
          <div className='d-flex w-100 justify-content-end'>
            <div className='d-flex justify-content-center' style={{ width: '95%' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab
                  label="All Contracts"
                  value="1"
                  className={`contracts-nav-heading theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}
                />
                <Tab
                  label="My Contracts"
                  value="2"
                  className={`contracts-nav-heading theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}
                />
              </TabList>
            </div>
            <div className="bar-icon my-2" style={{ transform: 'rotateZ(90deg)' }} onClick={handlePopoverOpen}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </div>
          </div>
        </Box>

        <Popover
          open={openPopover}
          anchorEl={popoverAnchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List
            className={` ' theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
              }`}
          >
            <ListItem onClick={() => handleFilterChange('4')} className={`cursor-pointer ${filterStatus == "4" ? `bg-danger-subtle` : ''}`}>All</ListItem>
            {value == "2"
              ? <ListItem onClick={() => handleFilterChange('0')} className={`cursor-pointer ${filterStatus == "0" ? `bg-danger-subtle` : ''}`}>Upcoming</ListItem>
              : ''}
            <ListItem onClick={() => handleFilterChange('1')} className={`cursor-pointer ${filterStatus == "1" ? `bg-danger-subtle` : ''}`}>Ongoing</ListItem>
            <ListItem onClick={() => handleFilterChange('3')} className={`cursor-pointer ${filterStatus == "3" ? `bg-danger-subtle` : ''}`}>Completed</ListItem>
          </List>
        </Popover>

        <TabPanel value="1">
          <InfiniteScroll
            dataLength={data.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h6></h6>}
            endMessage={
              <p style={{ textAlign: "center" }}></p>
            }
          >
            <div className="row all-dispute-list">
              {data && data.length > 0 ? (
                data.map((val, index) => {
                  let id = val?._id || null;
                  let senderImg = val?.senders?.profileImage || null;
                  let senderName = val?.senders?.name || null;
                  let senderEmail = val?.senders?.email || null;
                  let userEmail = JSON.parse(
                    localStorage.getItem("user")
                  ).email;
                  let uid =
                    senderEmail !== userEmail ? val?.senders?._id : val?.recipients?._id;
                  return (
                    <div key={index}>
                      <div className="d-flex mt-4 contracts-nav-card pb-2">
                        <span>
                          <img
                            src={senderImg}
                            alt=""
                            className="side-bar-profile object-fit-cover"
                          />
                        </span>
                        <div className="p-2 pt-3 w-100">
                          <h4>
                            {senderName}
                            <span className="view-contract">
                              {/* <Link
                                to={
                                  uid
                                    ? `/view-contract/${id}/${uid}`
                                    : `/view-contract/${id}`
                                }
                              >
                                View Contract
                              </Link> */}
                              <Link
                                to={`/message-send/${id}`}
                              >
                                View Contract
                              </Link>
                            </span>
                          </h4>
                          <p className={` 'user-description-rating theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                            {senderEmail}
                          </p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })
              ) : (
                <img src={NoMore} alt="" className="no-da mb-4" />
              )}
            </div>
          </InfiniteScroll>
        </TabPanel>
        <TabPanel value="2">
          <InfiniteScroll
            dataLength={data.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h6></h6>}
            endMessage={
              <p style={{ textAlign: "center" }}></p>
            }
          >
            <div className="row all-dispute-list">
              {data && data.length > 0 ? (
                data.map((val, index) => {
                  let id = val?._id || null;
                  let senderImg = val?.recipients?.profileImage || null;
                  let senderName = val?.recipients?.name || null;
                  let senderEmail = val?.recipients?.email || null;
                  let userEmail = JSON.parse(
                    localStorage.getItem("user")
                  ).email;
                  let uid =
                    senderEmail !== userEmail ? val?.senders?._id : val?.recipients?._id;

                  // console.log("contract-data", val)
                  return (
                    <div key={index}>
                      <div className="d-flex mt-4 contracts-nav-card pb-2">
                        <span>
                          <img
                            src={senderImg}
                            alt=""
                            className="side-bar-profile object-fit-cover"
                          />
                        </span>
                        <div className="p-2 pt-3 w-100">
                          <h4>
                            {senderName}
                            <span className="view-contract">
                              {/* <Link
                                to={
                                  uid
                                    ? `/view-contract/${id}/${uid}`
                                    : `/view-contract/${id}`
                                }
                              >
                                View Contract
                              </Link> */}
                              <Link
                                to={`/message-send/${id}`}
                              >
                                View Contract
                              </Link>
                            </span>
                          </h4>
                          <p className={` 'user-description-rating theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                            {senderEmail}
                          </p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })
              ) : (
                <img src={NoMore} alt="" className="no-da mb-4" />
              )}
            </div>
          </InfiniteScroll>
        </TabPanel>
      </TabContext>
    </Box>
  );
}
