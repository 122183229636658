import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import xmark from '../../../Images/x-mark.png';
import money1 from '../../../Images/money1.png';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import profile9 from '../../../Images/profile-9.png';
import { logout } from "../../../services/index";
import { notifyError, notifySuccess } from '../../../toastNotifications';
import logou from '../../../Images/logou.svg';

function CustomImage9() {
  return <img src={profile9} alt="Your Alt Text" className="me-2 image-inline" />;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,

};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function BasicModal() {
  const [open, setOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const hanldeLogout = async () => {
    try {
      const response = await logout();
      console.log("logout res -->", response)
      if (response.statusCode === 200) {
        localStorage.clear();
        window.location.href = "/home";
        notifySuccess("logout successfully");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const { status, message, statusCode } = error.response.data;
        console.log("Status:", status);
        console.log("Message:", message);
        console.log("StatusCode:", statusCode);
        notifyError("logout failed: " + message);
      } else {
        notifyError("logout failed: " + error.message);
      }
    }
  };

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div style={{ width: '100%' }}>
      <Tab
        label="Logout"
        icon={<CustomImage9 />}
        {...a11yProps(8)}
        onClick={handleOpen}
        className={`tabs-tab theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
        style={{ width: '100%' }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={` 'inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
          <img src={xmark} alt="" className='x-mark' onClick={handleClose} /> {/* Add onClick handler here */}
          <center>
            {/* <img src={money1} alt="" /> */}
          </center>
          <h5 className='text-center '>Logout </h5>
          <img src={logou} alt="" className='logou-img' />
          <p className={` ' text-center pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            <b>Are you sure, you want to logout your account?</b></p>

          <center className='mt-4'>
            <button className='cancel-btn mx-2' onClick={handleClose}>Cancel</button>
            <button className='logout-btn mx-2' onClick={hanldeLogout}>Logout</button>
          </center>
        </Box>
      </Modal>
    </div>
  );
}

export default BasicModal;
