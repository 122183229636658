import React, { useEffect, useState } from "react";
import profile from "../../../Images/Ellipse1.png";
import verified from "../../../Images/verified.png";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { List, ListItem, Popover } from "@mui/material";
import chatmedia from "../../../Images/cat-five1.png";
import Review from "./Review";
import AddDispute from "./AddDispute";
import {
  acceptOrRejectOrEndInvitation,
  invitationDetails,

} from "../../../services";
import { notifyError, notifySuccess } from "../../../toastNotifications";

function ViewContract() {
  const { id, uid } = useParams();
  const navigate = useNavigate();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [contractData, setContractData] = useState(null);


  const fetchContractDetails = async () => {
    let data = {
      messageId: id,
    };
    try {
      const response = await invitationDetails(data);
      if (response.statusCode === 200) {
        notifySuccess(response.message);
        setContractData(response.data);
      } else {
        notifyError(response.message);
      }
    } catch (error) {
      notifyError("Failed to fetch contract details.");
    }
  };

  useEffect(() => {
    fetchContractDetails();
  }, [id]);

  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const openPopover = Boolean(popoverAnchorEl);

  const handleEndContract = async () => {
    // Handle ending the contract logic
    let data = {
      messageId: id,
      isStatus: '3'
    }
    try {
      const response = await acceptOrRejectOrEndInvitation(data);
      console.log("acceptOrRejectOrEndInvitation response is here -->", response);
      if (response.statusCode === 200) {
        notifySuccess(response.message);
        navigate(`/profile`)
      }
    } catch (error) {
      console.error("Error in acceptOrRejectOrEndInvitation :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`)
    } finally {
      handlePopoverClose();
    }
  };

  return (
    <div>
      <div className="container-fluid background-main pb-5">
        <div className="container">
          <h2 className="search-note-h2 pt-5">Active Contracts</h2>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
              <Link to="/" className="">
                Contracts
              </Link>
              <Typography color="text.primary">
                <b>Active Contracts</b>
              </Typography>
            </Breadcrumbs>
          </div>
          {contractData && (
            <>
              <div className="row mt-5">
                <div className="d-flex">
                  {console.log(
                    contractData,
                    "contractDatacontractDatacontractData"
                  )}
                  <img
                    src={contractData?.message?.sender?._id === JSON.parse(localStorage.getItem('user'))._id ? contractData?.message.recipient?.profileImage : contractData?.message.sender?.profileImage}
                    alt="Profile"
                    className="user-profile object-fit-cover"
                  />
                  <span>
                    <h3 className="user-name d-flex mt-5 ms-4">
                      {contractData?.message?.sender?._id === JSON.parse(localStorage.getItem('user'))._id ? contractData?.message.recipient?.name : contractData?.message.sender?.name}
                      <span>
                        <img
                          src={verified}
                          alt="Verified"
                          className="mt-1 ms-2"
                        />
                      </span>
                    </h3>
                  </span>
                </div>
              </div>
              <div className="bar-icon mt-2" onClick={handlePopoverOpen}>
                <FontAwesomeIcon icon={faEllipsisH} />
              </div>
              <Popover
                open={openPopover}
                anchorEl={popoverAnchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <List>
                  <span>
                    <AddDispute msgId={id} />
                  </span>
                  {uid && (
                    <span>
                      <Review msgId={id} userId={uid} />
                    </span>
                  )}
                  <ListItem button onClick={handleEndContract}>
                    End Contract
                  </ListItem>
                </List>
              </Popover>
              <div className="row mt-5 activecontract">
                <h4>Question</h4>
                {/* <img src={chatmedia} alt="Chat media" className="chat-media" /> */}
                <p className="mt-2">
                  {contractData.message.messageList[0].message
                    ? contractData.message.messageList[0].message
                    : "Question"}
                </p>
                <h4 className="mt-4">Answer</h4>
                <p>
                  {contractData?.message?.messageList[1]?.message ? contractData?.message?.messageList[1]?.message : "Answer"}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewContract;
