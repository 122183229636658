import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notifySuccess = (message) => {
  console.log(message, "messagemessagemessagemessage");
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      background: "linear-gradient(to right, #F10B80, #830D9A)",
      color: "#fff",
    },
  });
};

export const notifyError = (message) => {
  console.log(message, "messagemessagemessagemessage");
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      background: "linear-gradient(to right, #F10B80, #830D9A)",
      color: "#fff",
    },
  });
};
