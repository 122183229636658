import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from "../ChangePasswordModal";
import EditProfileModal from "../EditProfileModal";
import ChangePhoneModal from "../ChangePhoneModal";
import image from '../../../Images/transformed-(1) 1.png';
import NavSection from '../AllContracts/NavSection';

function Overview() {

  return (
  <div className="container p-3 pt-5 pb-5">
      <div className="row px-3 row-top-margin">
      <div className="inner-shadow pt-3">
        <NavSection />
      </div>
    </div>
  </div>
  );
}

export default Overview;
