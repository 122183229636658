import axiosInstance from '../axiosInstance';

export const getWalletDetails = async () => {
    try {
        const response = await axiosInstance.get('/user/wallet/getWalletDetails');
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const addPaymentToWallet = async (data) => {
    try {
        const response = await axiosInstance.post('/user/wallet/addWallet', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getTransactionHistory = async () => {
    try {
        const response = await axiosInstance.get('/user/wallet/transactionHistory');
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const withdrawAmount = async (data) => {
    try {
        const response = await axiosInstance.post('/user/earning/withdrawRequest', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getMyEarnings = async (data) => {
    const { start, end } = data;
    try {
        const response = await axiosInstance.get(`/user/earning/myEarning?weekStartdate=${start}&weekEnddate=${end}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
