import React from 'react'
import whychooseicon1 from '../../Images/why-choose-icon-1.png';
import blogcard1 from '../../Images/blog-card-1.png';
import blogcard2 from '../../Images/blog-card-2.png';
import blogcard3 from '../../Images/blog-card-3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';

    const ReadOurBlog = () => {
      const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
    
      useEffect(() => {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
          setTheme(storedTheme);
        }
      }, []);
    
      useEffect(() => {
        localStorage.setItem('theme', theme);
      }, [theme]);
    
      return (
    <div id='blog'>
      <div className="container">
        <h2  className={` '  why-choose-h5 pt-5 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>Read Our Blog</h2>
        <div className="row mb-5">
        <div className="col-lg-4 col-md-6 mt-4 log">
            <div  className={` '  blog-card p-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>
              <img src={blogcard1} alt="" />
              <p  className={` '  pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>Design</p>
              <h5  className={` ' pt-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>How great Content helps drive success in Marketplace</h5>
              <button  className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>Read more  <FontAwesomeIcon icon={faArrowRight} className='blog-card-icon' /> </button>
              
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-4 log">
            <div  className={` '  blog-card p-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>
              <img src={blogcard2} alt="" />
              <p  className={` '  pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>Design</p>
              <h5  className={` ' pt-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>How great Content helps drive success in Marketplace</h5>
              <button  className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>Read more  <FontAwesomeIcon icon={faArrowRight} className='blog-card-icon' /> </button>
              
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-4 log">
            <div  className={` '  blog-card p-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>
              <img src={blogcard3} alt="" />
              <p  className={` '  pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>Design</p>
              <h5  className={` ' pt-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>How great Content helps drive success in Marketplace</h5>
              <button  className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>Read more  <FontAwesomeIcon icon={faArrowRight} className='blog-card-icon' /> </button>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReadOurBlog