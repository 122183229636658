import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from "../ChangePasswordModal";
import EditProfileModal from "../EditProfileModal";
import ChangePhoneModal from "../ChangePhoneModal";
// import image from '../../../Images/transformed-(1) 1.png';
import NavSection from '../AllContracts/NavSection';



const Overview = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (

    <div className="row px-3 row-top-margin">
      <div  className={`inner-shadow theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>
        <div
          className="side-bar-name-nav pb-2 mx-3 pt-2 text-center mt-4"
          id="wallet-section-two" >
          <h3  className={` ' overview-heading-wallet theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>All Contracts</h3>
          {/* <h2>all </h2> */}
          <div className="row">
            <NavSection />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
