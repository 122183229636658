import React, { useEffect, useState } from "react";
import Walletimage from "../../../Images/wepik-export-20231110071244qySj1.png";
import {
  addPaymentToWallet,
  getWalletDetails,
} from "../../../services/api/walletApi";
import { notifyError, notifySuccess } from "../../../toastNotifications";
import xmark from "../../../Images/x-mark.png";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Form from "react-bootstrap/Form";
import Loaders from "../../Loaders/index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Overview() {
  const [loading, setLoading] = useState(true);
  const [walletDetails, setWalletDetails] = useState(null);
  const [walletBal, setWalletBal] = useState(0);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  const [addMoney, setAddMoney] = useState(0);
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false);
  const [isAddingPayment, setIsAddingPayment] = useState(false);

  const handleOpenAddPaymentModal = () => {
    setAddMoney(0);
    setAddPaymentModalOpen(true);
  };

  const handleCloseAddPaymentModal = () => {
    setAddMoney(0);
    setAddPaymentModalOpen(false);
    setIsAddingPayment(false);
  };

  const handleGetWalletDetails = async () => {
    try {
      const response = await getWalletDetails();
      console.log("getWalletDetails response is here -->", response);
      if (response.statusCode === 200) {
        setWalletDetails(response.data.checkWallet);
        setWalletBal(response.data.checkWallet.ammount);
      }
    } catch (error) {
      console.error("Error in getWalletDetails :", error);
      notifyError(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false); // Set loading to false when data fetching is complete
    }
  };

  const handleAddWalletBal = async () => {
    if (!walletDetails) return;
    if (addMoney && addMoney > 10000000) {
      notifyError("You can add money up to 10,000,000 dollars only.");
      setAddMoney(0);
      return;
    } else {
      const data = {
        transactionId: walletDetails.transactionId,
        ammount: addMoney,
      };

      setIsAddingPayment(true); // Set loading state

      try {
        const response = await addPaymentToWallet(data);
        console.log("addPaymentToWallet response is here -->", response);
        if (response.statusCode === 200) {
          // notifySuccess(response.message);
          window.open(response?.data?.url, "_blank");
          // handleGetWalletDetails();
        }
      } catch (error) {
        console.error("Error in addPaymentToWallet :", error);
        notifyError(error?.response?.data?.message || "Something went wrong");
      } finally {
        handleCloseAddPaymentModal();
      }
    }
  };

  useEffect(() => {
    handleGetWalletDetails();
  }, []);

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  if (loading) {
    return <Loaders />; // Render the loader while loading is true
  }

  return (
    <>
      <div className="row px-3 row-top-margin">
        <div
          className={` ' inner-shadow theme-switcher ${
            theme === "dark" ? "dark-theme-only-color" : "light-theme"
          }`}
        >
          <div
            className="side-bar-name-nav pb-2 mx-3 pt-2 text-center mb-5 mt-4"
            id="wallet-section-two"
          >
            <h3
              className={` ' overview-heading-wallet theme-switcher ${
                theme === "dark" ? "dark-theme-only-color" : "light-theme"
              }`}
            >
              Wallet
            </h3>
            <center>
              <img src={Walletimage} alt="Wallet" />
            </center>
            <h3
              className={` ' overview-heading mt-4 theme-switcher ${
                theme === "dark" ? "dark-theme-only-color" : "light-theme"
              }`}
            >
              Balance
            </h3>
            <p
              className={` ' mx-5 theme-switcher ${
                theme === "dark" ? "dark-theme-only-color" : "light-theme"
              }`}
            >
              {`Your Balance in Wallet is`}
            </p>
            <b>${walletBal}</b>
            <center>
              <button
                className="payment-btn mt-4"
                onClick={handleOpenAddPaymentModal}
              >
                Add payment in Wallet
              </button>
            </center>
          </div>
        </div>
      </div>

      <div>
        <Modal
          open={addPaymentModalOpen}
          onClose={handleCloseAddPaymentModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            className={`inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${
              theme === "dark" ? "black-color" : "light-theme"
            }`}
          >
            <img
              src={xmark}
              alt=""
              className="x-mark"
              onClick={handleCloseAddPaymentModal}
            />
            <h5
              className={` 'text-center theme-switcher ${
                theme === "dark" ? "dark-theme-only-white" : "light-theme"
              }`}
            >
              Add Amount{" "}
            </h5>

            <Form.Label
              htmlFor="inputPasswordOld"
              className={` 'form-label-dark mt-3 theme-switcher ${
                theme === "dark" ? "dark-theme-only-white" : "light-theme"
              }`}
            >
              How much would you like to add
              <br /> (enter amount in $, use no decimal amount)
            </Form.Label>
            <div className="password-input-container">
              <Form.Control
                type="number"
                id="inputPasswordOld"
                aria-describedby="passwordHelpBlockOld"
                className="form-input mt-1 text-center"
                value={addMoney}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length <= 8) {
                    setAddMoney(parseInt(value));
                  }
                }}
              />

              {addMoney && addMoney > 0 ? (
                isAddingPayment ? (
                  <button className="payment-btn  w-100  mt-3">
                    Proceeding...
                  </button>
                ) : (
                  <button
                    className="search-btn w-100 mt-3"
                    onClick={handleAddWalletBal}
                  >
                    Proceed
                  </button>
                )
              ) : (
                // : <button className="search-btn w-100 mt-3" onClick={() => notifyError("atleast 1 allowed")}>Proceed</button>
                <button
                  className="search-btn w-100 mt-3"
                  onClick={() => notifyError("Amount cannot be empty")}
                >
                  Proceed
                </button>
              )}
            </div>
          </Box>
        </Modal>
        {/* <div>
          <Modal
            open={modelOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
              <img src={xmark} alt="" className='x-mark' onClick={handleModalClose} />
              <center>
                <img src={money1} alt="" />
              </center>
              <h5 className={` 'text-center theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}
              >Your Withdraw request sent successfully we will connect with you shortly. </h5>

              <center>
                <button onClick={handleModalClose} className='withdraw-btn-ok mt-3'>Ok</button>
              </center>
            </Box>
          </Modal>
        </div> */}
      </div>
    </>
  );
}

export default Overview;
