import React, { useState } from 'react';
import logo from '../../../Images/logo-mini.png';
import Form from 'react-bootstrap/Form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { forgotSetNewPassword } from '../../../services/api/authApi';
import { notifySuccess, notifyError } from "../../../toastNotifications";
import NavbarSignFlow from '../../Login/NavbarSignFlow'

function Index() {
    const navigate = useNavigate();
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    const handleSetNewPassword = async () => {
        if (!newPassword || newPassword.trim() === "") {
            notifyError('New password and confirm password required');
            return;
        } else if (newPassword !== confirmPassword) {
            notifyError('New password and confirm password do not match');
            return;
        } else {
            let data = {
                token: localStorage.getItem('token'),
                password: newPassword,
                confirmPassword: confirmPassword
            }
            try {
                const response = await forgotSetNewPassword(data);
                console.log("forgotSetNewPassword response is here -->", response);
                if (response.statusCode === 200) {
                    notifySuccess(response.message);
                    localStorage.clear();
                    navigate(`/login`)
                }
            } catch (error) {
                console.error("Error in forgotSetNewPassword :", error);
                notifyError(error?.response?.data?.message || `Something went wrong`)
            }
        }
    }


    return (
        <div>
            <NavbarSignFlow />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-7 login-left-section d-flex px-5">
                        <div className="justify-content-center align-self-center">
                            <h2>International ask Question with Ask Human!</h2>
                            <p>Enter the verification code we just sent on your email address.</p>
                        </div>
                    </div>
                    <div className="col-md-5 login-right-section m-auto pt-5">
                        {/* <p className='pt-4 px-4 login-right-section-p'>Don’t have an account? <span><b><Link to="/sign-up" className='forgot-password text-dark'>Signup</Link></b></span></p> */}
                        <div className="ask-to-sign login-right-section-flex px-5 pt-4 ">
                            <h2 className='d-flex'>
                                Set New Password <img src={logo} alt="" />
                            </h2>
                            <Form.Label htmlFor="inputPasswordNew" className='form-label mt-4'>New Password</Form.Label>
                            <div className="password-input-container">
                                <Form.Control
                                    type={showNewPassword ? "text" : "password"}
                                    id="inputPasswordNew"
                                    className='form-input mt-1'
                                    placeholder='Enter Password'
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <span onClick={toggleNewPasswordVisibility} className="toggle-password-icon">
                                    {!showNewPassword ? <FiEyeOff /> : <FiEye />}
                                </span>
                            </div>
                            <Form.Label htmlFor="inputPasswordConfirm" className='form-label mt-4'>Confirm Password</Form.Label>
                            <div className="password-input-container">
                                <Form.Control
                                    type={showConfirmPassword ? "text" : "password"}
                                    id="inputPasswordConfirm"
                                    className='form-input mt-1'
                                    placeholder='Confirm Password'
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <span onClick={toggleConfirmPasswordVisibility} className="toggle-password-icon">
                                    {!showConfirmPassword ? <FiEyeOff /> : <FiEye />}
                                </span>
                            </div>
                            <button className="search-btn w-100 mt-3" onClick={handleSetNewPassword}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
