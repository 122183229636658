import React, { useState, useEffect } from 'react';
import profile from "../../../Images/Ellipse1.png";
import verified from "../../../Images/verified.png";
import { Link, useNavigate } from 'react-router-dom';

function Popupmsg({ data, handleAcceptOrRejectPerPosal, msgId }) {
  const navigate = useNavigate();

  const [showPerposal, setShowPerposal] = useState(true);

  let image = data?.recipients?.profileImage || "";
  let name = data?.recipients?.name || "";
  let isVerified = data?.recipients?.videoVerify == 1;
  let about = data?.recipients?.about || "";
  let proposedMessage = data?.proposedMessage || "";
  let acceptStatus =
    (data?.isReferedStatus == 1 && data.isStatus == 0)
      ? 0
      : data?.isReferedStatus == 3 && data.isStatus == 2
        ? 2
        : data?.isReferedStatus == 2 && data.isStatus == 1
          ? 1 : null;

  const actionTaken = {
    backgroundColor: `#f2f2f2`,
    borderRadius: `10px`,
    border: `2px solid #dbe8ed`
  };

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div className={`'pt-5 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
      <div
        className={`popup-msg ${acceptStatus === 0 ? 'popup-msg' : ''} p-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
        style={actionTaken}
      >
        <div className={` ' row theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          <div className="d-flex">
            <img src={image} alt="" className="popup-msg-user-profile object-fit-cover" />
            <span className='w-100'>
              {" "}
              <div className='d-flex justify-content-between w-100 align-items-center'>
                <h5 className=" d-flex mt-2 ms-4">
                  {name}{" "}
                  <span>
                    {" "}
                    {isVerified ? <img src={verified} alt="" className="mt-1 ms-2" /> : ''}
                  </span>
                </h5>

                <p style={{ color: '#02a0e3', cursor: 'pointer' }} onClick={() => setShowPerposal(!showPerposal)}>
                  <b>{acceptStatus == 0 ? showPerposal ? `Hide Proposal` : `View Proposal` : ''}</b>
                </p>
              </div>

              <div className="">
                <p className="ms-4">
                  {about.substring(0, 180)}...
                </p>
              </div>
            </span>

          </div>
          {acceptStatus == 0
            ?
            <div className="perposal-section pt-2">
              {showPerposal ?
                <>
                  <h5>Proposal</h5>
                  <p>{proposedMessage}</p>
                </> : ''}
              <div className="m-auto mt-4">
                <span className="modal-button">
                  <button className="perposal-accept-btn" onClick={() => handleAcceptOrRejectPerPosal('2')} >Accept</button></span>
                <button className="save-draft-btn mt-4" onClick={() => handleAcceptOrRejectPerPosal('3')}>Reject</button>
              </div>
            </div>
            :
            <div className="m-auto mt-4 d-flex justify-content-center w-100">
              {
                acceptStatus == 1
                  ? < span style={{ color: '#F10B80' }} > Accepted your refer request</span>
                  : acceptStatus == 2
                    ? < span style={{ color: 'red' }} > Declined your refer request</span>
                    : ''
              }
            </div>}
        </div>

      </div>
    </div >
  )
}

export default Popupmsg