import axiosInstance from '../axiosInstance';

export const getUserReasonList = async () => {
    try {
        const response = await axiosInstance.get(`/user/dispute/getUserReasonList`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addDispute = async (data) => {
    try {
        const response = await axiosInstance.post(`/user/dispute/addDispute`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getMyDisputesList = async () => {
    try {
        const response = await axiosInstance.get(`/user/dispute/myDispute`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getDisputesList = async () => {
    try {
        const response = await axiosInstance.get(`/user/dispute/getDispute`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const endDispute = async (data) => {
    try {
        const response = await axiosInstance.put(`/user/dispute/endDispute`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};