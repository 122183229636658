import { useState, useEffect } from 'react';

const useUserMedia = (options) => {
  const [stream, setStream] = useState(null);

  useEffect(() => {
    const getUserMedia = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(options);
        setStream(stream);
      } catch (error) {
        console.error('Error accessing media devices:', error);
        setStream(false);
      }
    };

    if (!stream) {
      getUserMedia();
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
        setStream(null);
      } 
    };
  }, [stream, options]);

  return stream;
};

export default useUserMedia;
