import axios from 'axios';

const baseURL = process.env.REACT_APP_WEB_BASE_URL;
console.log(baseURL, "baseURL");

const axiosInstance = axios.create({
    baseURL,
    headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
        // Other default headers can be set here
    },
});
// Add a request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
      // Retrieve the token from local storage
      const token = localStorage.getItem('token');
      
      // If the token exists, add it to the headers
      if (token) {
        config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;

        
      }
       
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
export default axiosInstance;
