import React, { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "../../toastNotifications";
import { getMyNotifications } from "../../services";
import { useNavigate } from "react-router-dom";
import NoMore from '../../Images/no-da.svg'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPen } from "@fortawesome/free-solid-svg-icons";
// import ChangePasswordModal from "../ChangePasswordModal";
// import EditProfileModal from "../EditProfileModal";
// import ChangePhoneModal from "../ChangePhoneModal";
// import Added from '../../../Images/Added.png';
// import withdrawal from '../../../Images/withdrawal.png';
// import { getTransactionHistory } from "../../../services/api/walletApi";

function Notifications() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const handleMyNotifications = async () => {
        let data = {
            offset: 1,
            limit: 10
        }
        try {
            const response = await getMyNotifications(data);
            console.log("getMyNotifications response is here -->", response);
            if (response.statusCode === 200) {
                // notifySuccess(response.message);
                setData(response?.data?.notifications || [])
            }
        } catch (error) {
            console.error("Error in getMyNotifications :", error);
            notifyError(error?.response?.data?.message || `Something went wrong`)

        }
    }

    useEffect(() => {
        handleMyNotifications();
    }, []);

    return (
        <div className="row px-3 row-top-margin">
            <div className="inner-shadow pb-5">
                <h3 className="overview-heading-wallet text-center mt-4">
                    Notifications
                </h3>
                {data && data.length > 0
                    ?
                    <>
                        {
                            data.map((val, index) => {
                                let id = val?._id || null;
                                let message = val?.message || null;
                                let messageId = val?.messageId || null;
                                return (
                                    <div className="draft-driver p-4 mt-4" key={index} onClick={() => navigate(`/accept-reject/${messageId}`)}>
                                        <p>{message}</p>
                                    </div>
                                )
                            })
                        }
                    </>
                    : <img src={NoMore} alt="" className="no-da mb-4" />
                }
            </div>
        </div>
    );
}

export default Notifications;
