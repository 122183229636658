import React from 'react'
import whychooseicon1 from '../../Images/why-choose-icon-1.png';
import whychooseicon2 from '../../Images/why-choose-icon-2.png';
import whychooseicon3 from '../../Images/why-choose-icon-3.png';
import { useState, useEffect } from 'react';

const WhyChoose = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div>
      <div className="container why-choose">
        <h5 className={`why-choose-h5  pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Why Choose Ask Human!</h5>

        <div className="row pb-5 pt-2">

          <div className="col-lg-4 col-md-6 mt-4">
            <div className="why-choose-card p-4 card-hover">
              <img src={whychooseicon1} alt="" />
              <h5 className={`mt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Ask Your Questions</h5>
              <p className={` theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Ask anything, from simple inquiries to complex problems. Our experts are here to provide you with the best possible answers, tailored to your specific needs.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mt-4 mt-4">
            <div className="why-choose-card p-4 card-hover">
              <img src={whychooseicon2} alt="" />
              <h5 className={`mt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Get Expert Answers</h5>
              <p className={` theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Receive clear, accurate, and personalized responses from our team of real human experts. We ensure you get the reliable information you need quickly and efficiently.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mt-4 mt-4">
            <div className="why-choose-card p-4 card-hover">
              <img src={whychooseicon3} alt="" />
              <h5 className={`mt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Affordable Pricing</h5>
              <p className={` theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Enjoy competitive pricing for expert advice. Our transparent, affordable rates ensure you get great value for your money without compromising on quality </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyChoose