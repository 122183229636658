import React, { useState } from "react";
import { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "../../Images/Avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import Overview from "./Overview";
import Wallet from "./Wallet/index";
import DraftList from "./DraftList/index";
import ContactUs from "./ContactUs/index";
import AllContracts from "./AllContracts/index";
import AllDisputes from "./AllDisputes/index";
import TransectionHistory from "./TransectionHistory/index";
import MyEarning from "./MyEarning/index";
import AppliedRequest from "./AppliedRequests/AppliedRequest";
import Logout from "./Logout/index";
import Identificationn from "./Identification/index";
import YourImage from "../../Images/user.png";
import profile2 from "../../Images/profile-2.png";
import profile3 from "../../Images/profile-3.png";
import profile4 from "../../Images/profile-4.png";
import profile5 from "../../Images/profile-5.png";
import profile6 from "../../Images/profile-6.png";
import profile7 from "../../Images/profile-7.png";
import profile8 from "../../Images/profile-8.png";
import profile9 from "../../Images/profile-9.png";
import { AccountCircle } from "@mui/icons-material"; // Importing AccountCircle icon from Material-UI icons
import { getMyProfile } from "../../services/api/profileApi";
// import Loaders from "../Loaders/index";

function CustomImage() {
  return (
    <img src={YourImage} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage2() {
  return (
    <img src={profile2} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage3() {
  return (
    <img src={profile3} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage4() {
  return (
    <img src={profile4} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage5() {
  return (
    <img src={profile5} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage6() {
  return (
    <img src={profile6} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage7() {
  return (
    <img src={profile7} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage8() {
  return (
    <img src={profile8} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage9() {
  return (
    <img src={profile9} alt="Your Alt Text" className="me-2 image-inline" />
  );
}

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const VerticalTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  // const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getMyProfile();
        console.log(data.data.user, "datadatadata");
        setProfile(data.data.user);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchProfile();
  }, []);

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  // console.log("modalOpen", modalOpen)

  if (loading) {
    return (
      <div>
        {/* <Loaders /> */}
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container">
      <div className="row mt-5 mb-5">
        <div className="col-lg-3 side-nav">
          <div
            className={`inner-shadow pt-5 p-2  theme-switcher ${theme === "dark" ? "inner-shadow-dark" : "light-theme"
              }`}
          >
            
            <center>
              <img
                src={profile.profileImage}
                alt=""
                className="side-bar-profile object-fit-cover"
              />
            </center>
            <div className="side-bar-name pb-3">
              <h2
                className={`theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                  }`}
              >
                {profile.name.length > 28
                  ? `${profile.name.substring(0, 28)}...`
                  : profile.name}
              </h2>
              <p
                className={`pb-2 theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                  }`}
              >
                {profile.email.length > 20
                  ? `${profile.email.substring(0, 20)}...`
                  : profile.email}
              </p>
             
              <div className="pb-3 side-bar-name pt-2">

              
      <div style={{ display: profile && profile?.videoVerify && profile?.videoVerify === 1 ? 'none' : 'block' }}>
                
              <Identificationn />
              </div>
            </div>
            </div>
            <div>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 0, borderColor: "divider" }}
              >
                <Tab
                  label="My Account"
                  icon={<CustomImage />}
                  {...a11yProps(0)}
                  className={` tabs-tab theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                    }`}
                />
                <Tab
                  label="Wallet"
                  icon={<CustomImage2 />}
                  {...a11yProps(1)}
                  className={` tabs-tab theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                    }`}
                />
                <Tab
                  label="Draft List"
                  icon={<CustomImage5 />}
                  {...a11yProps(2)}
                  className={` tabs-tab theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                    }`}
                />
                <Tab
                  label="Contact us"
                  icon={<CustomImage4 />}
                  {...a11yProps(3)}
                  className={` tabs-tab theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                    }`}
                />
                <Tab
                  label="All Contracts"
                  icon={<CustomImage5 />}
                  {...a11yProps(4)}
                  className={` tabs-tab theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                    }`}
                />
                <Tab
                  label="Applied Requests"
                  icon={<CustomImage6 />}
                  {...a11yProps(10)}
                  className={`tabs-tab theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                    }`}
                />
                <Tab
                  label="All Disputes"
                  icon={<CustomImage6 />}
                  {...a11yProps(5)}
                  className={` tabs-tab theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                    }`}
                />
                <Tab
                  label="Transaction History"
                  icon={<CustomImage7 />}
                  {...a11yProps(6)}
                  className={` tabs-tab theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                    }`}
                />
                <Tab
                  label="Earnings"
                  icon={<CustomImage8 />}
                  {...a11yProps(7)}
                  className={` tabs-tab theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                    }`}
                />

                <div
                  // onClick={() => setModalOpen(true)} 
                  className={`tabs-tab theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                    }`}>
                  <Logout
                  // modalOpen={modalOpen} 
                  />
                </div>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="col-lg-9 p-0">
          <TabPanel value={value} index={0} sx={{ Padding: "0px" }}>
            <Overview />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Wallet />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <DraftList />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ContactUs />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <AllContracts />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <AppliedRequest />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <AllDisputes />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <TransectionHistory />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <MyEarning />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default VerticalTabs;
