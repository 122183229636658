import React, { useRef, useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { notifyError } from "../../../toastNotifications";

const ForgotPasswordOTPVerify = ({ handleFillOtp }) => {
  const inputRefs = useRef(Array.from({ length: 4 }, () => React.createRef()));
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);

  // useEffect(() => {
  //   const mobileOtp = localStorage.getItem("mobileOtp");
  //   console.log(mobileOtp,"mobileOtp")
  //   if (mobileOtp) {
  //     const otpArray = mobileOtp.split("").slice(0, 4);
  //     setOtpInputs(otpArray);
  //   }
  // }, []);

  const handleInputChange = (index, value, isBackOrDelete, isLeft, isRight) => {
    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = value;
    setOtpInputs(newOtpInputs);
    handleFillOtp(newOtpInputs);
    
    if (isBackOrDelete || value === "") {
      inputRefs.current[index].current.value = "";
      let OtpInputsTempArr = [...otpInputs];
      OtpInputsTempArr[index] = ""
      setOtpInputs(OtpInputsTempArr);
    } else if (index < inputRefs.current.length || value !== "") {
      inputRefs.current[index].current.value = value
      inputRefs.current[index < inputRefs.current.length - 1 ? index + 1 : index].current.focus();
    }

    if (isLeft && index > 0) {
      inputRefs.current[index - 1].current.focus();
    }

    if (isRight && index < inputRefs.current.length) {
      inputRefs.current[index + 1].current.focus();
    }

  };

  // const handlePaste = (event) => {
  //   const pastedData = event.clipboardData.getData('text');
  //   console.log("pastedData", pastedData)
  //   // setOtp(pastedData); // Update OTP state with pasted data
  // };

  useEffect(() => {
    const handlePaste = (event) => {
      const pastedData = event.clipboardData.getData('text');
      // Assuming you have some logic to determine if the pasted content is OTP
      // For simplicity, let's say OTP is any 6-digit number
      const otpRegex = /^\d{4}$/;
      if (otpRegex.test(pastedData)) {
        console.log("pastedData", pastedData)
        let splitedArr = pastedData.split("");
        setOtpInputs(splitedArr);
        handleFillOtp(splitedArr);
      } else {
        // console.log("pastedData is wrong", pastedData)
        notifyError("Invalid otp pasted");
        setOtpInputs(["", "", "", ""]);
      }
    };

    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, []); // Empty dependency array to run effect only once


  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <Box mb={3}>
      <Grid container spacing={1} justifyContent="center">
        {inputRefs.current.map((ref, index) => (
          <Grid item key={index} >
            <input
              type="text"
              className={` ' otp__digit otp__field__${index + 1} theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}
              id={`input-${index}`}
              inputMode="numeric"
              maxLength={1}
              ref={ref}
              value={otpInputs[index]}
              onChange={(e) => { console.log("hello world"); handleInputChange(index, e.target.value) }}
              pattern="[0-9]+"
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9]/g, "")
                  .replace(/(\..*)\./g, "$1");
                  console.log("hello world on input")
              }}
              // onPaste={handlePaste}
              style={{
                height: "55px",
                width: "55px",
                textAlign: "center",
                fontSize: "20px",
                border: "1px solid #DBDBDB",
                borderRadius: "5px",
              }}
              onKeyDown={(e) => {
                e.preventDefault();
                // console.log("e", e.key)
                let numKeys = ['0','1','2','3','4','5','6','7','8','9'];
                const isBackspace = e.keyCode === 8;
                const isDelete = e.keyCode === 46;
                const isBackOrDelete = isBackspace || isDelete;
                const isLeft = e.key == 'ArrowLeft';
                const isRight = e.key == 'ArrowRight';
                if (isBackspace || isDelete || isLeft || isRight) {
                  handleInputChange(index, e.target.value, isBackOrDelete, isLeft, isRight);
                }else if(numKeys.includes(e.key)) {
                  handleInputChange(index, e.key, isBackOrDelete, isLeft, isRight);
                }

              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ForgotPasswordOTPVerify;
