import React from 'react';
import Footer from "../../Footer";

const PrivacyPolicy = () => {
  return (
    <>
    <div className=" container privacy-policy-container pb-5 pt-3 mt-5">
      <h1 className='text-center'>Privacy Policy</h1>
      <p className='mt-3'>
        <strong>Introduction</strong>
      </p>
      <p>
        At Ask Human, we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines the types of information we collect, how we use and safeguard that information, and your rights regarding your personal data. By using our services, you agree to the terms of this Privacy Policy.
      </p>
      <p className='mt-3'>
        <strong>Information We Collect</strong>
      </p>
      <p>
        1. Personal Information: We collect personal information that you provide to us when you create an account, post questions, or provide answers. This may include your name, email address, phone number, payment information, and any other details you choose to provide.
      </p>
      <p>
        2. Usage Information: We collect information about your interactions with our platform, such as the pages you visit, the questions you ask, the answers you provide, and the time and date of your activities.
      </p>
      <p>
        3. Cookies and Tracking Technologies: We use cookies and similar technologies to enhance your experience on our platform. These technologies help us understand how you use our services and enable us to personalize your experience.
      </p>
      <p className='mt-3'>
        <strong className='mt-3'>How We Use Your Information</strong>
      </p>
      <p>
        1. Provide and Improve Services: We use your personal information to provide and improve our services, process transactions, communicate with you, and enhance your overall experience on our platform.
      </p>
      <p>
        2. Personalization: We use information collected from cookies and tracking technologies to personalize your experience, such as remembering your preferences and showing you content relevant to your interests.
      </p>
      <p>
        3. Communication: We use your contact information to send you updates, promotional materials, and other information related to our services. You can opt out of receiving marketing communications at any time.
      </p>
      <p>
        4. Security and Fraud Prevention: We use your information to maintain the security of our platform, prevent fraud, and protect our users.
      </p>
      <p className='mt-3'>
        <strong>Information Sharing and Disclosure</strong>
      </p>
      <p>
        1. With Your Consent: We may share your information with third parties when you have given us explicit consent to do so.
      </p>
      <p>
        2. Service Providers: We may share your information with trusted service providers who assist us in operating our platform, conducting our business, or providing services to you. These providers are contractually obligated to protect your information and use it only for the purposes we specify.
      </p>
      <p>
        3. Legal Requirements: We may disclose your information if required by law, regulation, legal process, or government request, or if we believe such disclosure is necessary to protect our rights, property, or safety, or that of our users or others.
      </p>
      <p className='mt-3'>
        <strong>Data Security</strong>
      </p>
      <p>
        We implement a variety of security measures to protect your personal information from unauthorized access, use, or disclosure. These measures include encryption, access controls, and regular security assessments. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
      </p>
      <p className='mt-3'>
        <strong>Your Rights</strong>
      </p>
      <p>
        1. Access and Correction: You have the right to access and correct your personal information held by us. You can update your account information through your profile settings or by contacting our customer support team.
      </p>
      <p>
        2. Data Portability: You have the right to request a copy of your personal information in a structured, commonly used, and machine-readable format.
      </p>
      <p>
        3. Deletion: You have the right to request the deletion of your personal information. We will comply with your request unless we are required to retain certain information for legal or legitimate business purposes.
      </p>
      <p>
        4. Opt-Out: You have the right to opt out of receiving marketing communications from us. You can do this by following the unsubscribe instructions in our emails or by contacting us directly.
      </p>
      <p className='mt-3'>
        <strong>Changes to This Privacy Policy</strong>
      </p>
      <p>
        We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the new policy on our website and updating the "Effective Date" at the top of this page. Your continued use of our services after the changes take effect constitutes your acceptance of the revised policy.
      </p>
      <p className='mt-3'>
        <strong>Contact Us</strong>
      </p>
      <p>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact our customer support team at privacy@askhuman.com.
      </p>
      <p>
        By using our platform, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.
      </p>
    </div>
    <Footer />
    </>
  );
};

export default PrivacyPolicy;
