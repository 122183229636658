import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from "../ChangePasswordModal";
import EditProfileModal from "../EditProfileModal";
import ChangePhoneModal from "../ChangePhoneModal";
import Added from '../../../Images/Added.png';
import withdrawal from '../../../Images/withdrawal.png';
import { notifyError, notifySuccess } from "../../../toastNotifications";
import { getTransactionHistory } from "../../../services/api/walletApi";
import { formatDate } from "../../../utils/dateFormatter";
import NoMore from '../../../Images/no-da.svg'
import Loaders from '../../Loaders/index'

function Overview() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleGetTransactionHistory = async () => {
        try {
            const response = await getTransactionHistory();
            console.log("getTransactionHistory response is here -->", response);
            if (response.statusCode === 200) {
                // notifySuccess(response.message);
                setData(response.data)
            }
        } catch (error) {
            console.error("Error in getTransactionHistory :", error);
            notifyError(error?.response?.data?.message || `Something went wrong`)

        }
        finally {
            setLoading(false); // Set loading to false when data fetching is complete
        }
    }
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
    useEffect(() => {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
            setTheme(storedTheme);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);


    useEffect(() => {
        handleGetTransactionHistory();
    }, []);
    if (loading) {
        return <Loaders />; // Render the loader while loading is true
    }

    return (
        <div className="row px-3 row-top-margin">
            <div className={` ' inner-shadow theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
                <div className="side-bar-name-nav pb-2 mx-3 pt-2 text-center mb-5 mt-4"
                    id="wallet-section-two">
                    <h3 className={` ' overview-heading-wallet theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Transaction History</h3>
                    <div className="row text-start mt-5">
                        {data && data.length > 0
                            ?
                            <>
                                {
                                    data.map((val, index) => {

                                        let id = val?._id || null;
                                        let type = val?.type || null;

                                        let icon =
                                            type == "Amount Deduct"
                                                ? withdrawal
                                                : type == 'Withdrawal Request'
                                                    ? withdrawal
                                                    : type == "Earning"
                                                        ? Added
                                                        : type == "Refund Amount"
                                                            ? Added
                                                            : type == "Add"
                                                                ? Added : null;

                                        let mode =
                                            type == "Amount Deduct"
                                                ? `Amount Deduct`
                                                : type == 'Withdrawal Request'
                                                    ? `Withdrawal`
                                                    : type == "Earning"
                                                        ? `Earning`
                                                        : type == "Refund Amount"
                                                            ? `Refund Amount`
                                                            : type == "Add"
                                                                ? `Added` : null;

                                        let money_color = 
                                        type == "Amount Deduct"
                                        ? `transection-info-doller-withdrawal`
                                        : type == 'Withdrawal Request'
                                            ? `transection-info-doller-withdrawal`
                                            : type == "Earning"
                                                ? `transection-info-doller`
                                                : type == "Refund Amount"
                                                    ? `transection-info-doller`
                                                    : type == "Add"
                                                        ? `transection-info-doller` : null;
                                        
                                        
                                        let amount = val?.ammount || 0;
                                        let time = val?.createdAt || new Date();



                                        return (
                                            <div className="col-12" key={index}>
                                                <div className="d-flex">
                                                    <span>
                                                        <img src={icon} alt="" className="transection-info-icon" />
                                                    </span>
                                                    <span className="transection-info">
                                                        <h2 className={`'px-2 mb-0 pt-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{mode}</h2>
                                                        <p className="p-0 px-2">{formatDate(time)}</p>
                                                    </span>
                                                </div>
                                                <span className={`float-right ${money_color}`}>
                                                    {`$${amount}`}
                                                </span>
                                            </div>
                                        )
                                    })
                                }
                            </>
                            : <img src={NoMore} alt="" className="no-da mb-4" />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Overview;
