
import axiosInstance from '../axiosInstance';

export const createReview = async (data) => {
    try {
        const response = await axiosInstance.post('/review/createReview', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};