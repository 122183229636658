import io from 'socket.io-client';


const socket = io(process.env.REACT_APP_WEB_SOCKET_URL);

export const connectSocket = () => {
    socket.on('connect', () => {
        console.log('Socket.IO connected');
        emitAddUserSocket();
    });
}
export const emitAddUserSocket = () => {
    socket.emit('addUser', { userId: "66838734067664a16d90f470" });
}

export const emitRealTimeMessagesSocket = () => {
    socket.emit('getUserRealtimeMessages', { userId: "66838734067664a16d90f470" });
}

export const onRealTimeMessagesSocket = () => {
    socket.on('getUserRealtimeMessages', (msg) => {
        console.log("Received message:", msg);
        return msg
    });
}

export const disconnectSocket = () => {
    socket.on('disconnect', () => {
        console.log('Socket.IO disconnected');
    });
}

// export const useNotificationSocket = () => {
//     let messages = '';

//     socket.on('connect', () => {
//         console.log('Socket.IO connected');
//         socket.emit('addUser', { userId: "66838734067664a16d90f470" });
//     });

//     socket.emit('getUserRealtimeMessages', { userId: "66838734067664a16d90f470" });
//     socket.on('getUserRealtimeMessages', (msg) => {
//         console.log("Received message:", msg);
//         messages = msg
//     });

//     socket.on('disconnect', () => {
//         console.log('Socket.IO disconnected');
//     });

//     return messages;
// };