import io from "socket.io-client";
import React, { createContext, useState } from "react";

const socketMessageContext = createContext({});
const socket = io(process.env.REACT_APP_WEB_SOCKET_URL);

const SocketMessageProvider = ({ children }) => {


    const [socketMessageData, setSocketMessageData] = useState(null);
    const [unreadAlerts, setUnreadAlerts] = useState(0);

    const handleMessageSetter = (data) => {
        setSocketMessageData(data);
    }

    return (
        <socketMessageContext.Provider value={{ socket, socketMessageData, handleMessageSetter, unreadAlerts, setUnreadAlerts }}>
            {children}
        </socketMessageContext.Provider>
    )
}

export { socket, socketMessageContext, SocketMessageProvider };

