import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import xmark from '../../../Images/x-mark.png';
import RequestSuccessfullyModal from './RequestSuccessfullyModal';
import { withdrawAmount } from '../../../services/api/walletApi';
import { notifyError, notifySuccess } from '../../../toastNotifications';
import money1 from '../../../Images/money1.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ handleGetWalletDetails }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [withdrawReqMoney, setWithdrawReqMoney] = useState(0);
  const [modelOpen, setModalOpen] = React.useState(false);
  const handleModalClose = () => setModalOpen(false);


  const handleWithdraw = async () => {

    try {
      const response = await withdrawAmount({ ammount: withdrawReqMoney });
      console.log("withdrawAmount response is here -->", response);
      if (response.statusCode === 200) {
        notifySuccess(response.message);
        handleClose();
        handleGetWalletDetails();
        setModalOpen(true);
      }
    } catch (error) {
      console.error("Error in withdrawAmount :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`)
      handleClose();
    }
  }

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);



  return (
    <div>
      <button onClick={handleOpen} className='withdraw-btn'>WITHDRAW</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}
        >
          <img src={xmark} alt="" className='x-mark' onClick={handleClose} /> {/* Add onClick handler here */}
          <h5 className={` 'text-center theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}
          >Withdraw Amount </h5>

          <Form.Label htmlFor="inputPasswordOld" className={` 'form-label-dark mt-3 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}
          >
            How much would you like to withdraw
            <br /> (enter amount in $, use no decimal amount)</Form.Label>
          <div className="password-input-container">
            <Form.Control
              type="number"
              id="inputPasswordOld"
              aria-describedby="passwordHelpBlockOld"
              className='form-input mt-1 text-center'
              value={withdrawReqMoney}
              onChange={(e) => setWithdrawReqMoney(parseInt(e.target.value))}
            />
            {withdrawReqMoney && withdrawReqMoney > 0
              ?
              <button className="search-btn w-100 mt-3" onClick={handleWithdraw}>Proceed</button>
              : <button className="search-btn w-100 mt-3" onClick={() => notifyError("atleast 1 allowed")}>Proceed</button>
            }
          </div>
        </Box>
      </Modal>
      <div>
        <Modal
          open={modelOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
            <img src={xmark} alt="" className='x-mark' onClick={handleModalClose} /> {/* Add onClick handler here */}
            <center>
              <img src={money1} alt="" />
            </center>
            <h5 className={` 'text-center theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}
            >Your Withdraw request sent successfully we will connect with you shortly. </h5>

            <center>
              <button onClick={handleModalClose} className='withdraw-btn-ok mt-3'>Ok</button>
            </center>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
