import React, { useEffect, useState } from "react";
import logo from "../../Images/logo-mini.png";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import fillter from "../../Images/fillter.svg";
import HomeCard from "./HomeCard";
import { experimentalStyled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import profile from "../../Images/Ellipse1.png";
import verified from "../../Images/verified.png";
import profileimg from "../../Images/Rectangle75.png";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { getUserDetails } from "../../services";
import { notifyError, notifySuccess } from "../../toastNotifications";
import Loaders from "../Loaders/index";
import { inviteSingleUser, referInvitation } from "../../services/api/contractApi";
import { FaStar } from "react-icons/fa";
import StarIcon from '@mui/icons-material/Star';



function Index() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  

  const { id, nid } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);

  const handleGetUserDetails = async () => {
    try {
      const response = await getUserDetails(id);
      console.log("getUserDetails response is here -->", response);
      if (response.statusCode === 200) {
        // notifySuccess(response.message);
        setUserDetails(response.data.User);
      }
    } catch (error) {
      console.error("Error in getUserDetails :", error);
    } finally {
      setLoading(false); // Set loading to false when data fetching is complete
    }
  };

  const handleInviteUser = async () => {
    let data = {
      inviteId: id,
      notesId: nid,
    };
    try {
      const response = await inviteSingleUser(data);
      console.log("invite to single user response is here -->", response);
      if (response.statusCode === 200) {
        notifySuccess(response.message);
        localStorage.removeItem("saved-note");
        navigate("/search-note");
      }
    } catch (error) {
      console.error("Error in inviting a single user :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    }
  };

  const handleRefer = async () => {
    let notification = JSON.parse(localStorage.getItem("notification"));
    console.log("notification", notification)
    if (!notification) {
      notifyError(`Something went wrong`);
      navigate(-1);
    } else {
      let data = {
        ...notification,
        referTo: [id]
      }

      try {
        const response = await referInvitation(data);
        console.log("referInvitation response is here -->", response);
        if (response.statusCode === 200) {
          notifySuccess(response.message);
          localStorage.removeItem('notification');
          localStorage.removeItem("saved-note");
          navigate('/search-note')
          // setContractData(response.data);
        }
      } catch (error) {
        console.error("Error in referInvitation :", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
        // navigate(`/profile`)
      }
    }
  }

  const [refer, setRefer] = useState(false);

  useEffect(() => {
    handleGetUserDetails();
    let route = window.location.pathname;
    let isReferRoute = route.includes('/user-detail-refer');
    if (isReferRoute) {
      setRefer(true)
    }
  }, []);


  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);


  if (loading) {
    return <Loaders />; // Render the loader while loading is true
  }

  return (
    <div>
      <div className={` ' container-fluid background-main pb-5 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
        <div className="container">
          <h2 className="search-note-h2 pt-5">User Detail</h2>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
              <Link underline="hover" color="inherit" href="/home" to="/home" className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>
                Home
              </Link>
              <Link underline="hover" color="inherit" href="/" to="/user-list"  className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>
                User listing
              </Link>
    
              <Typography color="text.primary">
                {" "}
                <b className={`theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>User Detail</b>
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="row mt-5">
            <div className="d-flex">
              <img src={userDetails?.profileImage || profile} alt="" className="user-profile object-fit-cover" />
              <span>
                {" "}
                <h3 className="user-name d-flex mt-4 ms-4">
                  {userDetails?.name || ""}{" "}

                  {" "}

                  {userDetails?.videoVerify ? (
                    <span className="mt-1 ms-2">
                      <img src={verified} alt="verified" />
                    </span>
                  ) : null}

                </h3>
                {/* <Link to="/message"> */}
                <button
                  className="search-btn-invitation ms-3 mt-2"
                  onClick={refer ? handleRefer : handleInviteUser}
                >
                  {refer
                    ? `Send Reference`
                    : `Send Invitation`}
                </button>
                {/* </Link> */}
              </span>
            </div>
          </div>

          <div className="row mt-5">
            <h3 className="user-name">Description</h3>
            <p className="user-description">
              {userDetails?.about || `No description yet`}
            </p>
          </div>

          <div className="row mt-5">
            <h3 className="user-name">
              Review {userDetails?.avgStarCount !== null ? `(${userDetails?.avgStarCount.toFixed(1)})` : ''}
              {userDetails?.avgStarCount && (<FontAwesomeIcon icon={faStar} color="#FFA115" />)} 
            </h3>
            <div className="d-flex mt-2 flex-column">
              {userDetails?.reviews?.length > 0 ? (
                userDetails.reviews.map((review, index) => (
                  <div key={index} className="review-item mb-3">
                    <div className="d-flex">
                      <img
                        src={review.reviewerProfileImage}
                        alt="Reviewer"
                        className="user-profile-new"
                      />
                      <div className="review-details ms-3 mt-2">
                        <h4 className="user-name">{review.reviewerName}</h4>
                        <div className="star-rating d-flex pt-1">
                          <Rating
                            name="text-feedback"
                            value={review.starCount}
                            precision={0.5}
                            readOnly
                            className="mt-0"
                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                          />
                        </div>
                      </div>
                    </div>
                    <p className="pt-2 comment-users">{review.comment}</p>
                  </div>
                ))
              ) : (
                <p className='text-center pt-4'>No reviews yet</p>
              )}
            </div>


          </div>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default Index;
