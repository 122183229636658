import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// import Form from 'react-bootstrap/Form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import xmark from "../../Images/x-mark.png";
import { changePassword } from '../../services/api/profileApi'; // Adjust the import path as necessary
import { notifyError, notifySuccess } from "../../toastNotifications";

import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfNewPassword, setShowConfNewPassword] = useState(false);

  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [oldPassword, setOldPassword] = useState('');
  // const [newPassword, setNewPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');
  // const [error, setError] = useState(null);
  // const [success, setSuccess] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // setError(null);
    // setSuccess(null);
    // setOldPassword('');
    // setNewPassword('');
    // setConfirmPassword('');
  };

  const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
  const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
  const toggleConfNewPasswordVisibility = () => setShowConfNewPassword(!showConfNewPassword);


  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().trim()
      .test('notEmptyAfterTrim', 'Old Password is required!', value => value.trim() !== '')
      .required("Old Password is required!"),

    newPassword: Yup.string().trim()
      .test('notEmptyAfterTrim', 'New Password is required!', value => value.trim() !== '')
      // .min(6, "New Password must be at least 6 characters long")
      .required("New Password is required!")
      .min(10, 'Password must be at least 10 characters long')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[@$!%*?&#]/, 'Password must contain at least one symbol (@$!%*?&#)')
      .required('Password is required'),

    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm Password is required!')

  });


  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("values", values)
    setSubmitting(true);
    const { oldPassword, newPassword, confirmNewPassword } = values;
    const data = {
      oldPassword: oldPassword.trim(),
      newPassword: newPassword.trim(),
      confirmPassword: confirmNewPassword.trim()
    };
    try {
      const response = await changePassword(data);
      console.log("changePassword response is", response);
      if (response.statusCode === 200) {
        handleClose();
        notifySuccess(response.message);
        localStorage.clear();
        navigate('/login');
      }
    } catch (error) {
      console.error("Error logging in:", error);
      handleClose();
      notifyError(error?.response?.data?.message || `Something went wrong`)
    } finally {
      setSubmitting(false);
    }

  };

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);


  // const handleChangePassword = async () => {
  //   if (newPassword !== confirmPassword) {
  //     setError('New password and confirm password do not match');
  //     return;
  //   }

  //   const data = {
  //     oldPassword: oldPassword,
  //     newPassword: newPassword,
  //     confirmPassword: confirmPassword
  //   };

  //   try {
  //     const response = await changePassword(data);
  //     notifySuccess('Password changed successfully');
  //     handleClose();
  //   } catch (error) {
  //     setError('Error changing password');
  //     console.error('Error changing password:', error);
  //   }
  // };

  return (
    <div>
      <button className="change-phone-number-btn mt-2" onClick={handleOpen}>Change</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
          <img src={xmark} alt="Close" className="x-mark" onClick={handleClose} />
          <h5 className='text-center'>Change Password</h5>

          <Formik
            initialValues={{
              oldPassword: '',
              newPassword: '',
              confirmNewPassword: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mt-3">
                  <label htmlFor="oldPassword" className={` ' form-label theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                    Old Password
                  </label>
                  <div className="password-input-container">
                    <Field
                      type={showOldPassword ? "text" : "password"}
                      id="oldPassword"
                      name="oldPassword"
                      className={` ' form-input mt-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                    />
                    <span
                      onClick={toggleOldPasswordVisibility}
                      className="toggle-password-icon"
                    >
                      {!showOldPassword ? <FiEyeOff /> : <FiEye />}
                    </span>
                  </div>
                  <ErrorMessage name="oldPassword" component="div" className="text-danger" />
                </div>

                <div className="mt-3">
                  <label htmlFor="newPassword" className={` ' form-label theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                    New Password
                  </label>
                  <div className="password-input-container">
                    <Field
                      type={showNewPassword ? "text" : "password"}
                      id="newPassword"
                      name="newPassword"
                      className={` ' form-input mt-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                    />
                    <span
                      onClick={toggleNewPasswordVisibility}
                      className="toggle-password-icon"
                    >
                      {!showNewPassword ? <FiEyeOff /> : <FiEye />}
                    </span>
                  </div>
                  <ErrorMessage name="newPassword" component="div" className="text-danger" />
                </div>

                <div className="mt-3">
                  <label htmlFor="confirmNewPassword" className={` ' form-label theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                    Confirm New Password
                  </label>
                  <div className="password-input-container">
                    <Field
                      type={showConfNewPassword ? "text" : "password"}
                      id="confirmNewPassword"
                      name="confirmNewPassword"
                      className={` ' form-input mt-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                    />
                    <span
                      onClick={toggleConfNewPasswordVisibility}
                      className="toggle-password-icon"
                    >
                      {!showConfNewPassword ? <FiEyeOff /> : <FiEye />}
                    </span>
                  </div>
                  <ErrorMessage name="confirmNewPassword" component="div" className="text-danger" />
                </div>
                {isSubmitting ?
                  <button
                    type="submit"
                    className="search-btn w-100 mt-3"
                    disabled={true}
                  >
                    Saving changes...
                  </button> :
                  <button
                    type="submit"
                    className="search-btn w-100 mt-3"
                    disabled={isSubmitting}
                  >
                    Confirm
                  </button>}
              </Form>
            )}
          </Formik>

          {/* <Form.Label htmlFor="inputPasswordOld" className='form-label mt-4'>Old Password</Form.Label>
          <div className="password-input-container">
            <Form.Control
              type={showOldPassword ? "text" : "password"}
              id="inputPasswordOld"
              className='form-input mt-1'
              placeholder='Enter old Password'
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <span onClick={toggleOldPasswordVisibility} className="toggle-password-icon">
              {showOldPassword ? <FiEyeOff /> : <FiEye />}
            </span>
          </div>

          <Form.Label htmlFor="inputPasswordNew" className='form-label mt-4'>New Password</Form.Label>
          <div className="password-input-container">
            <Form.Control
              type={showNewPassword ? "text" : "password"}
              id="inputPasswordNew"
              className='form-input mt-1'
              placeholder='Enter Password'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <span onClick={toggleNewPasswordVisibility} className="toggle-password-icon">
              {showNewPassword ? <FiEyeOff /> : <FiEye />}
            </span>
          </div>

          <Form.Label htmlFor="inputPasswordConfirm" className='form-label mt-4'>Confirm Password</Form.Label>
          <div className="password-input-container">
            <Form.Control
              type={showConfirmPassword ? "text" : "password"}
              id="inputPasswordConfirm"
              className='form-input mt-1'
              placeholder='Confirm Password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <span onClick={toggleConfirmPasswordVisibility} className="toggle-password-icon">
              {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
            </span>
          </div>

          <button className="search-btn w-100 mt-3" onClick={handleChangePassword}>Confirm</button> */}
        </Box>
      </Modal>
    </div>
  );
}
