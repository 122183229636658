import React, { useState, useEffect } from 'react';
import girl from '../../Images/girl.png';
import animationline from '../../Images/animation-line.png';
import animationrating from '../../Images/animation-rating.png';
import animationdone from '../../Images/animation-done.png';
import animationvisitor from '../../Images/animation-visitor.png';
import animationicon from '../../Images/animation-icon.png';
import animation from '../../Images/animation.png';
import WhyChoose from './WhyChoose';
import TrustedUs from './TrustedUs';
import ReadOurBlog from './ReadOurBlog';
import CustomerSaying from './CustomerSaying';
import Subscribe from './Subscribe';
import NavbarLogout from './NavbarLogout'

const Index = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const token = localStorage.getItem('token');

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div>
      {/* <NavbarLogout /> */}
      <div className={`container-fluid landing-section mb-5 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>

        <div className="container">
          <div className="row pt-5">
            <div className="col-md-5 build-business pt-5">
              <h2 className={` pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                Ask anything - Get answer from real human opinions
              </h2>
              <p className={` pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                Join Ask Human to ask any question and receive personalized answers
                from knowledgeable experts. Earn rewards by sharing your expertise and
                helping others with their queries. Connect with a community dedicated to
                knowledge exchange and mutual learning.
              </p>
              {/* <a href="mailto:ideiosoft@gmail.com" > <button className="logout-btn mt-4">Contact Us</button>
             </a> */}
                <a href={token ? "/search-note" : "/login"}>
      <button className="logout-btn mt-4">Get a Quote</button>
    </a>
             {/* <a href="/search-note" > <button className="logout-btn mt-4">Get a Quotes</button></a> */}
            </div>
            <div className="col-md-7">
              <img src={girl} alt="" className="animtion-girl" />
              <div className="">
                <img src={animationline} alt="" className="animation-line" id="animation-none" />
                <img src={animationrating} alt="" className="animation-rating" id="animation-none" />
                <img src={animationdone} alt="" className="animation-done" id="animation-none" />
                <img src={animationvisitor} alt="" className="animation-visitor" id="animation-none" />
                <img src={animationicon} alt="" className="animation-icon" id="animation-none" />
                <img src={animation} alt="" className="animation" id="animation-none" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhyChoose />
      <TrustedUs />
      <ReadOurBlog />
      <CustomerSaying />
      <Subscribe  />
    </div>
  );
}

export default Index;
