import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// import Form from 'react-bootstrap/Form';
import xmark from "../../Images/x-mark.png";
import { changeEmail } from '../../services/api/profileApi'; // Adjust the import path as necessary
import { notifySuccess, notifyError } from "../../toastNotifications";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // const [newEmail, setNewEmail] = useState('');
  // const [confirmEmail, setConfirmEmail] = useState('');
  // const [error, setError] = useState(null);
  // const [success, setSuccess] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // setError(null);
    // setSuccess(null);
    // setNewEmail('');
    // setConfirmEmail('');
  };


// const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.(?:com|in|net|org|edu|gov|co\.uk)$/;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, "Invalid email format")
      .required("Email is required!"),
    // confEmail: Yup.string()
    //   .oneOf([Yup.ref('email'), null], 'Emails must match')
    //   .required("confirm Email is required!"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("values", values)
    const { email } = values;
    const data = {
      email: email.trim()
    };
    try {
      const response = await changeEmail(data);
      console.log("changeEmail response is", response);
      if (response.statusCode === 200) {
        handleClose();
        notifySuccess(response.message);
        localStorage.clear();
        navigate('/login');
      }
    } catch (error) {
      console.error("Error logging in:", error);
      handleClose();
      notifyError(error?.response?.data?.message || `Something went wrong`)
    } finally {
      setSubmitting(false);
    }
  
  };

  	
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);
  



    // try {
    //   await changeEmail(data);
    //   notifySuccess('Email changed successfully');
    //   handleClose();
    // } catch (error) {
    //   setError('Error changing email');
    //   console.error('Error changing email:', error);
    // }


  return (
    <div>
      <button className="change-phone-number-btn mt-2" onClick={handleOpen}>Change</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme' }`}>
          <img src={xmark} alt="Close" className="x-mark" onClick={handleClose} />
          <h5 className='text-center'>Change Email</h5>
          <Formik
            initialValues={{
              email: '',
              confEmail: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mt-4">
                  <label htmlFor="inputEmailNew" className={`text-center form-label theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>
                  Your new email must be different from previous used email.
                  </label>
                  <Field
                    type="email"
                    id="inputEmailNew"
                    name="email"
                    placeholder="Enter your new email address"
                    className={`form-input mt-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}
                  />
                  <ErrorMessage name="email" component="div" className="text-danger" />
                </div>



                {/* <div className="mt-4">
                  <label htmlFor="inputEmailConfirm" className={` ' form-label theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>
                    Confirm Email
                  </label>
                  <Field
                    type="email"
                    id="inputEmailConfirm"
                    name="confEmail"
                    className={` ' form-input mt-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}
                  />
                  <ErrorMessage name="confEmail" component="div" className="text-danger" />
                </div> */}

                {/* <Form.Label htmlFor="inputEmailNew" className='form-label mt-4'>New Email</Form.Label>
                <Form.Control
                  type="email"
                  id="inputEmailNew"
                  className='form-input mt-1'
                  placeholder='Enter new email'
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                />

                <Form.Label htmlFor="inputEmailConfirm" className='form-label mt-4'>Confirm Email</Form.Label>
                <Form.Control
                  type="email"
                  id="inputEmailConfirm"
                  className='form-input mt-1'
                  placeholder='Confirm new email'
                  value={confirmEmail}
                  onChange={(e) => setConfirmEmail(e.target.value)}
                /> */}

                {/* <button className="search-btn w-100 mt-3" onClick={handleChangeEmail}>Confirm</button> */}
                {isSubmitting ?
                  <button
                    type="submit"
                    className="search-btn w-100 mt-3"
                    disabled={true}
                  >
                    Saving Changes...
                  </button> :
                  <button
                    type="submit"
                    className="search-btn w-100 mt-3"
                    disabled={isSubmitting}
                  >
                    Confirm
                  </button>}
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}
