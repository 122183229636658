import React, { useState, useEffect } from 'react';
import Searchimage from "../../../Images/search-image.png";
import Messagesend from "../../../Images/message-send.png";
import { notifyError } from '../../../toastNotifications';
import Button from "@mui/material/Button";
import { photoUpload } from '../../../services';

function ReferAcceptReject({ handleMessageSend, handleMediaSend }) {
  const [message, setMessage] = useState("");
  const submitHandler = () => {
    if (!message || (message && message.trim() == "")) {
      notifyError("Please type your message");
      setMessage("");
    } else {
      handleMessageSend(message);
      setMessage("");
    }
  }


  const handleFileChange = async (event) => {
    console.log("working================================here")
    try {
      const file = event.target.files[0];
      console.log(file, "filefilefilefile");
      if (!file) {
        console.error("No file selected");
        notifyError(`No file selected`)
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        const dataURL = reader.result;
        console.log(dataURL, "dataURL");
        const byteString = atob(dataURL.split(",")[1]);
        const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        const formData = new FormData();
        formData.append("file", blob, file.name);
        try {
          const imageUpload = await photoUpload(formData);
          if (imageUpload.statusCode === 200) {
            console.log(imageUpload.data.imageUrl, "imageUpload.data.imageUrl")
            handleMediaSend(imageUpload?.data?.imageUrl);
            setMessage("");
          }
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div>
      <div className={` ' pb-4  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>
        <p style={{ color: "transparent" }}></p>
        <div   className={` '  align-items-center d-flex justify-content-between mt-4 search-bar w-100 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>
          <div>
            <input
              type="file"
              id="profileImage"
              name="profileImage"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <label htmlFor="profileImage" className='ms-2'>
              <span className="p-3">
                <img
                  src={Searchimage}
                  alt=""
                  className="search-bar-image"
                />
              </span>
            </label>
          </div>

          <input
            type="text"
            placeholder="Answer here ..."
            className="p-2"
            value={message}
            style={{ color: `${theme == "dark" ? `white` : 'black'}` }}
            onChange={(e) => setMessage(e.target.value)}
          />
          <span className="p-3" onClick={submitHandler} >
            <img
              src={Messagesend}
              alt=""
              className="search-bar-image"
            />
          </span>
        </div>
      </div>
    </div >
  )
}

export default ReferAcceptReject