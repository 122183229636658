import React, { useState, useEffect, useRef, useCallback } from "react";
import profileimg from "../../../Images/Ellipse1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";

import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { formatDateAndTime } from "../../../utils/dateFormatter";
import { useNavigate } from "react-router-dom";
import { bookMarkOrDeleteMessage } from "../../../services";
import { notifyError, notifySuccess } from "../../../toastNotifications";
import { socket } from "../../../context/SocketMessageContext";
import Badge from '@mui/material/Badge';

function UserChat({
  msgId,
  image,
  name,
  isOnline,
  contractId,
  lastMessage,
  unreadCount,
  updatedAt,
  isFav,
}) {
  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    console.log("Dropdown toggle clicked"); // Log when the dropdown is toggled
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    },
    [dropdownRef]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleFavOrDelMessage = async (status) => {
    let data = {
      messageId: msgId,
      userId: JSON.parse(localStorage.getItem("user"))._id,
    };
    socket.emit(`${status == 1 ? `favoriteBy` : `deletedBy`}`, data);
    window.location.reload();

    return () => socket.removeAllListeners();
  };

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <div
      onClick={() =>
        navigate(msgId ? `/message-send/${msgId}` : "/message-send")
      }
    >
      <div className="d-flex user-chat pb-2 p-4 position-relative">
        <span>



          <img
            src={image}
            alt=""
            className="object-fit-cover side-bar-profile"
            style={{ maxWidth: "85px" }}
          />

          {/* {isOnline ? (
                 <Badge color="secondary" badgeContent=" ">
       </Badge>
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={regularHeart}
                      className="favorite-heart-icon me-2" 
                    />
                    Favorite
                  </>
                )} */}


          <p className='active-status' style={{ background: isOnline ? '#f10b80' : '#a6a6a6' }}></p>
        </span>
        <div className="ps-3 pt-0 w-100">
          <h4
            className={` p-0 m-0 pb-1  theme-switcher ${theme === "dark" ? "hover-white-color" : "light-theme"
              }`}
          >

            {name}
            <span className="message-time-new">{formatDateAndTime(updatedAt)}</span>
          </h4>
          <h6 className="contract-id pt-1 mb-0">
            Contract Id : {contractId}
            {unreadCount && (
              <button className="message-count">{unreadCount}</button>
            )}
          </h6>
          <p className="pt-0 mt-0">
            <b>
              <div
                className={` d-flex justify-content-between  theme-switcher ${theme === "dark" ? "hover-white-color" : "light-theme"
                  }`}
              >
                <span>
                  {lastMessage.split(' ').slice(0, 10).join(' ') + (lastMessage.split(' ').length > 10 ? '...' : '')}
                </span>

                <span className="d-flex align-items-center gap-4">
                  {isFav && (
                    <FontAwesomeIcon
                      icon={solidHeart}
                      style={{ color: "red", fontSize: "18px" }}
                    />
                  )}
                  <span
                    className="message-favorite"
                    id="drop-arrow"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleDropdown();
                    }}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </span>
                </span>
              </div>
            </b>
          </p>
          {dropdownOpen && (
            <div className={` ' dropdown-chat theme-switcher ${theme === "dark" ? "dropdown-chat-dark" : "light-theme"
              }`} ref={dropdownRef}>
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  handleFavOrDelMessage(1);
                }}
              >
                {isFav ? (
                  <>
                    <FontAwesomeIcon
                      icon={solidHeart}
                      className="me-2"
                      style={{ color: "red", fontSize: "18px" }}
                    />
                    UnFavorite
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={regularHeart}
                      className="favorite-heart-icon me-2"
                    />
                    Favorite
                  </>
                )}
              </p>

              <hr className="m-0" />
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  handleFavOrDelMessage(0);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className={` ' delete-user-icon me-2 theme-switcher ${theme === "dark" ? "dropdown-chat-dark-color" : "light-theme"
                    }`}
                />{" "}
                Delete Chat
              </p>
            </div>
          )}
        </div>
      </div>
      <hr className="m-0" />
    </div>
  );
}

export default UserChat;
