import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Footer from "../../Footer";

const FAQAccordion = () => {
  return (
    <div>
      <div className="main-header-section mt-4">
        <h1>How It Works</h1>
        
      </div>
      
      <div className="container mt-5 pb-2">
      <p className='pb-3'><b>Ask Questions</b></p>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <strong>1. Create an Account</strong>
          </AccordionSummary>
          <AccordionDetails>
            Start by signing up on our platform. It's quick and easy—just provide your email address or use social media for a fast setup.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <strong>2. Post Your Question</strong>
          </AccordionSummary>
          <AccordionDetails>
            Once you're logged in, you can post your question. Be as detailed as possible to ensure you get the most accurate and helpful response.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <strong>3. Invite Experts</strong>
          </AccordionSummary>
          <AccordionDetails>
            Browse our list of experts and select someone with the relevant expertise. Send them an invitation to chat, describing your question or issue briefly.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4-content"
            id="panel4-header"
          >
            <strong>4. Payment</strong>
          </AccordionSummary>
          <AccordionDetails>
            When an expert accepts your invitation, you will be billed on an hourly basis for the chat. Our payment system is transparent and secure.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5-content"
            id="panel5-header"
          >
            <strong>5. Receive Answers</strong>
          </AccordionSummary>
          <AccordionDetails>
            The expert will provide you with detailed and personalized answers to your question. If you need further clarification, you can continue the discussion within the chat.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6-content"
            id="panel6-header"
          >
            <strong>6. Feedback and Dispute</strong>
          </AccordionSummary>
          <AccordionDetails>
            After the session, you can provide feedback on the expert's performance. If you're not satisfied with the answer, you can dispute the payment for a refund.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7-content"
            id="panel7-header"
          >
            <strong>7. Invite Referral</strong>
          </AccordionSummary>
          <AccordionDetails>
            If an expert is not available, you can refer the invitation to another expert who might be interested in providing answers.
          </AccordionDetails>
        </Accordion>
        <p className='pb-3 pt-4'><b>Answer and Earn</b></p>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8-content"
            id="panel8-header"
          >
            <strong>1. Create an Account (Answer and Earn)</strong>
          </AccordionSummary>
          <AccordionDetails>
            Sign up to become an expert on our platform. Complete your profile with details of your expertise and experience to attract relevant queries.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9-content"
            id="panel9-header"
          >
            <strong>2. Browse Invitations</strong>
          </AccordionSummary>
          <AccordionDetails>
            Check the list of incoming invitations from users seeking answers. Review the questions and decide which ones you are interested in answering.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10-content"
            id="panel10-header"
          >
            <strong>3. Accept Invitations</strong>
          </AccordionSummary>
          <AccordionDetails>
            If a question matches your expertise, accept the invitation to start a chat. You will be notified when a user selects you for their query.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11-content"
            id="panel11-header"
          >
            <strong>4. Provide Answers</strong>
          </AccordionSummary>
          <AccordionDetails>
            Engage with the user through the chat to provide thorough and accurate answers. Make sure to address their query in detail and offer valuable insights.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12-content"
            id="panel12-header"
          >
            <strong>5. Get Paid</strong>
          </AccordionSummary>
          <AccordionDetails>
            You will be paid based on the duration of the chat and the complexity of the answer. Payments are processed promptly and transparently.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel13-content"
            id="panel13-header"
          >
            <strong>6. Feedback and Referrals</strong>
          </AccordionSummary>
          <AccordionDetails>
            After the session, you can get feedback on your answers. If you’re unavailable for an invitation, you can refer it to another expert who might be interested.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel14-content"
            id="panel14-header"
          >
            <strong>7. Dispute and Support</strong>
          </AccordionSummary>
          <AccordionDetails>
            In case of any issues or disputes, follow our dispute process for resolution. Our support team is available to help with any questions or concerns you might have.
          </AccordionDetails>
        </Accordion>
      </div>
      <Footer />
    </div>
  );
};

export default FAQAccordion;
