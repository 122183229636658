import React, { useEffect, useState } from 'react';
import { getMyEarnings } from '../../../services/api/walletApi';
import { notifyError, notifySuccess } from '../../../toastNotifications';
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import {
  getStartOfWeekSunday,
  getNextWeekStartDate,
  getPreviousWeekStartDate,
  getWeekEndDate,
  formatDate
} from '../../../utils/dateFormatter';

export default function StackedBarChart() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [ammountperWeek, setAmmountperWeek] = useState(0);
  const [totalContact, setTotalContact] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [chartXAxis, setChartXAxis] = useState('weekly');
  const [weekData, setWeekData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [monthData, setMonthData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [yearData, setYearData] = useState([0]);
  const [weekStartDate, setWeekStartDate] = useState(getStartOfWeekSunday());
  const [weekEndDate, setWeekEndDate] = useState(getWeekEndDate(weekStartDate));
  const [yearAxis, setYearAxis] = useState([new Date().getFullYear()]);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  
  const xLabels = {
    weekly: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthly: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    yearly: yearAxis
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const options = {
    series: [{
      name: '$',
      data: chartXAxis === 'weekly' ? weekData : chartXAxis === 'monthly' ? monthData : yearData
    }],
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        colors: {
          ranges: [{
            from: 0,
            to: Infinity,
            color: '#D20d71'
          }]
        }
      }
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    xaxis: {
      categories: xLabels[chartXAxis],
      position: 'bottom',
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
      tooltip: {
        enabled: true,
      }
    },
    yaxis: {
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return '$' + val;
        }
      }
    },
    grid: {
      show: false, // Hide grid lines
    },
    title: {
      text: 'Monthly Inflation in Argentina, 2002',
      floating: true,
      offsetY: 330,
      align: 'center',
      style: {
        color: '#444'
      }
    },
    theme: {
      mode: theme,
      palette: 'palette1',
      monochrome: {
        enabled: false,
        color: '#255aee',
        shadeTo: 'light',
        shadeIntensity: 0.65
      }
    }
  };

  const weekDataHandler = (val) => {
    const tempArr = val.map(element => element.totalAmount);
    setWeekData(tempArr.length ? tempArr : [0, 0, 0, 0, 0, 0, 0]);
  };

  const monthDataHandler = (val) => {
    const tempArr = val.map(element => element.totalAmount);
    setMonthData(tempArr.length ? tempArr : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  };

  const yearDataHandler = (val) => {
    const tempArr = val.map(element => element.totalAmount);
    const tempYearsArr = val.map(element => element.year);
    setYearData(tempArr.length ? tempArr : [0]);
    setYearAxis(tempYearsArr.length ? tempYearsArr : [new Date().getFullYear()]);
  };

  const handleGetMyEarnings = async () => {
    const data = {
      start: weekStartDate,
      end: weekEndDate,
    };
    try {
      const response = await getMyEarnings(data);
      if (response.statusCode === 200) {
        setAmmountperWeek(response?.data?.ammountperWeek || 0);
        setTotalContact(response?.data?.totalContact || 0);
        setTotalEarnings(response?.data?.totalEarnings || 0);
        weekDataHandler(response?.data?.weekGraph || []);
        monthDataHandler(response?.data?.monthGraph || []);
        yearDataHandler(response?.data?.getMonthYear || []);
      }
    } catch (error) {
      notifyError(error?.response?.data?.message || `Something went wrong`);
    }
  };

  const handlePrevWeek = () => {
    const result = getPreviousWeekStartDate(weekStartDate);
    setWeekStartDate(result);
    setWeekEndDate(getWeekEndDate(result));
  };

  const handleNextWeek = () => {
    if (new Date() > new Date(weekEndDate)) {
      const result = getNextWeekStartDate(weekStartDate);
      setWeekStartDate(result);
      setWeekEndDate(getWeekEndDate(result));
    }
  };

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  useEffect(() => {
    handleGetMyEarnings();
  }, [weekStartDate]);

  return (
    <>
      <div className='d-flex justify-content-evenly align-items-center pt-4'>
        <FontAwesomeIcon icon={faAngleLeft} onClick={handlePrevWeek} />
        <p>{formatDate(weekStartDate)} - {formatDate(weekEndDate)}</p>
        <FontAwesomeIcon icon={faAngleRight} onClick={handleNextWeek} />
      </div>
      <h5><b>${ammountperWeek}</b></h5>
      <div className="w-100">
        <div className="dropdown dropdown-custom mt-5 float-right">
          <button
            className={`btn dropdown-toggle fillter-btn ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}
            type="button"
            onClick={toggleDropdown}
          >
            Filter by
          </button>
          <ul className={`dropdown-menu${isDropdownOpen ? ' show' : ''}`} aria-labelledby="filterDropdown">
            <li className="dropdown-item cursor-pointer" onClick={() => setChartXAxis('weekly')}>Weekly</li>
            <li className="dropdown-item cursor-pointer" onClick={() => setChartXAxis('monthly')}>Monthly</li>
            <li className="dropdown-item cursor-pointer" onClick={() => setChartXAxis('yearly')}>Yearly</li>
          </ul>
        </div>
        <Chart options={options} series={options.series} type="bar" width="500" />
        <div className="row">
          <div  className={` ' total-earning p-3 mx-2 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme' }`}>
            <p  className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>Total Earning</p>
            <h5 className='pt-2'><b>${totalEarnings}</b></h5>
          </div>
          <div  className={` ' total-earning p-3 mx-2 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme' }`}>
            <p  className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>Total Contract</p>
            <h5 className='pt-2'><b>{totalContact}</b></h5>
          </div>
        </div>
      </div>
    </>
  );
}
