import React, { useContext, useEffect, useState } from 'react';
import * as Yup from "yup";
import logo from '../../Images/logo-mini.png';
// import Form from 'react-bootstrap/Form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { styled } from '@mui/material/styles';
import google from '../../Images/google.png';
import apple from '../../Images/apple.png';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import Switch from '@mui/material/Switch'; // Import Switch from MUI
import { signIn, socialLogin } from '../../services/api/authApi'; // Import signIn function from your authApi service
import { notifySuccess, notifyError } from '../../toastNotifications'; // Import notification functions
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { socket, socketMessageContext } from '../../context/SocketMessageContext';
import { signInWithGooglePopup } from '../../firebase/config'; // Adjust the path based on your actual folder structure
import NavbarSignFlow from '../Login/NavbarSignFlow'

function Index() {
  // const handleGoogleLogin = async () => {
  //   try {
  //     const result = await auth.signInWithPopup(googleProvider);
  //     const user = result.user;
  //     console.log('Logged in user:', user);
  //     // Redirect or perform actions after successful login
  //   } catch (error) {
  //     console.error('Google login failed:', error.message);
  //   }
  // };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithGooglePopup();
      const user = result.user;
      console.log('Logged in user:', user);
      if (user && user?.uid) {
        let data = {
          socialId: user?.uid,
          socialType: "google",
          email: user?.email,
          fcmToken: "web"
        }
        try {
          const response = await socialLogin(data);
          console.log('socialLogin RESPONSE here :', response);
          if (response.statusCode === 200) {
            socket.emit('addUser', response.data.user._id);
            notifySuccess("Logged in successfully!");
            if (response.data.user.profileComplete == 0) {
              navigate('/create-profile')
            } else {
              navigate('/home'); // Redirect to the home page
            }
          }
        } catch (error) {
          console.log('socialLogin ERROR here :', error);
          notifyError(error?.response?.data?.message || `Something went wrong`)
        }
      } else {
        throw new Error('No user found')
      }
      // Handle successful login
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      notifyError(error?.response?.data?.message || `Something went wrong`)
    }
  };
  // const socket = useContext(socketContext)
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#E30B83' : '#E30B83',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.(?:com|in|net|org|edu|gov|co\.uk)$/;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, "Invalid email format")
      .required("Email is required!"),

    password: Yup.string().trim()
      .test('notEmptyAfterTrim', 'Password is required!', value => value.trim() !== '')
      .required("Password is required!"),
  });

  const [showPassword, setShowPassword] = useState(false);
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [loading, setLoading] = useState(false);
  const [isSafari, setIsSafari] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   try {
  //     const userData = { emailOrPhone: email, password, fcmToken: '' };
  //     const response = await signIn(userData);
  //     console.log(response, "response");
  //     if (response.statusCode === 200) {
  //       notifySuccess("Logged in successfully!");
  //       if (!response.data.user.name) {
  //         navigate('/create-profile')
  //       } else {
  //         navigate('/home'); // Redirect to the home page
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error logging in:", error);
  //     notifyError(error?.response?.data?.message || `Something went wrong`)
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async (values, { setSubmitting }) => {
    const { email, password } = values;
    try {
      const userData = { emailOrPhone: email, password, fcmToken: 'web' };
      const response = await signIn(userData);
      console.log(response, "response");
      if (response.statusCode === 200) {
        socket.emit('addUser', response.data.user._id);
        notifySuccess("Logged in successfully!");
        if (response.data.user.profileComplete == 0) {
          navigate('/create-profile')
        } else {
          navigate('/home'); // Redirect to the home page
        }
      }
    } catch (error) {
      console.error("Error logging in:", error);
      notifyError(error?.response?.data?.message || `Something went wrong`)
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsSafari(/^((?!chrome|android).)*safari/i.test(userAgent));
    // setIsChrome(/chrome/i.test(userAgent));
  }, []);

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);





  return (
    <>
    <NavbarSignFlow/>
    <div className={` ' container-fluid theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`} >
      
      <div className="row">
        <div className="col-md-7 login-left-section d-flex px-5">
          <div className="justify-content-center align-self-center">
            <h2>International ask Question with Ask Human!</h2>
            <p>Enter the email and password for Signin Ask Human!</p>
          </div>
        </div>
        <div className={` ' col-md-5 login-right-section m-auto theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
          {/* <p className='p-4 login-right-section-p'>
            Don’t have an account? <b><Link to="/sign-up" className={` '  sign-up-text text-dark theme-switcher ${theme === 'dark' ? 'dark-mode-pink-color' : 'light-theme'}`}>Sign up</Link></b>
          </p> */}
          <div className="ask-to-sign px-5 mt-5 pt-3">
            <h2 className='d-flex pt-5 mt-5'>
              Signin to Ask Human! <img src={logo} alt="Ask Human Logo" />
            </h2>
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mt-5">
                    <label htmlFor="inputEmail" className={` ' form-label theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                      Email
                    </label>
                    <Field
                      type="email"
                      id="inputEmail"
                      name="email"
                      placeholder="Enter Your Email"
                      className={` ' form-input mt-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                    />
                    <ErrorMessage name="email" component="div" className="text-danger" />
                  </div>
                  <div className="mt-3">
                    <label htmlFor="password" className={` ' form-label theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                      Password
                    </label>
                    <div className="password-input-container">
                      <Field
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Enter Your Password"
                        className={` ' form-input mt-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        className="toggle-password-icon"
                      >
                        {!showPassword ? <FiEyeOff /> : <FiEye />}
                      </span>
                    </div>
                    <ErrorMessage name="password" component="div" className="text-danger" />
                  </div>
                  <p className='mt-2 remember-me'>
                    <span className='d-flex'>
                      <span className='mt-1'>
                        <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} /> {'\u00A0'}{'\u00A0'}
                      </span>
                      {'\u00A0'} Remember me
                    </span>
                    <span className='float-end forgot-password'>
                      <Link to="/forgot-password" className='forgot-password'>Forgot password?</Link>
                    </span>
                  </p>
                  {isSubmitting ?
                    <button
                      type="submit"
                      className="sign-in-btn mt-4"
                      disabled={true}
                    >
                      Signing in...
                    </button> :
                    <button
                      type="submit"
                      className="sign-in-btn mt-4"
                      disabled={isSubmitting}
                    >
                      Sign in
                    </button>}
                </Form>
              )}
            </Formik>
            {/* <Form onSubmit={handleSubmit}>
              <Form.Label htmlFor="inputEmail" className='form-label mt-5'>Email</Form.Label>
              <Form.Control
                type="email"
                id="inputEmail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='form-input mt-1'
                required
              />
              <Form.Label htmlFor="inputPassword" className='form-label mt-3'>Password</Form.Label>
              <div className="password-input-container">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  id="inputPassword"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className='form-input mt-1'
                  required
                />
                <span onClick={togglePasswordVisibility} className="toggle-password-icon">
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </span>
              </div>
              <p className='mt-2 remember-me'>
                <span className='d-flex'>
                  <span className='mt-1'>
                    <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} /> {'\u00A0'}{'\u00A0'}
                  </span>
                  {'\u00A0'} Remember me
                </span>
                <span className='float-end forgot-password'>
                  <Link to="/forgot-password" className='forgot-password'>Forgot password?</Link>
                </span>
              </p>
              <button type="submit" className='sign-in-btn' disabled={loading}>
                {loading ? 'Signing in...' : 'Sign in'}
              </button>
            </Form> */}
            <p className='continue-with mt-4 ' >Or continue with</p>
            <center className="button-center d-flex mt-4 mb-5">
              <button
                className={isSafari ? `google-btn mx-2` : `google-btn mx-2 w-100 d-flex justify-content-center`}
                onClick={signInWithGoogle}>
                <img src={google} alt="Google" /> {'\u00A0'}Google</button>
              {isSafari ? <button className='google-btn mx-2'><img src={apple} alt="Apple" /> {'\u00A0'}Apple</button> : ''}
            </center>
          </div>

        </div>
      </div>
    </div >
    </>
  );
}

export default Index;
