import React, { useState, useEffect } from 'react';
import LeftLine from '../../Images/right-line.png';
import AnimationSaying1 from '../../Images/animation-saying-1.png';
import AnimationSaying2 from '../../Images/animation-saying-2.png';
import AnimationSaying3 from '../../Images/animation-saying-3.png';
import AnimationSaying4 from '../../Images/animation-saying-4.png';
import AnimationSaying5 from '../../Images/animation-saying-5.png';
import AnimationSaying6 from '../../Images/animation-saying-1.png';
import RightLine from '../../Images/left-lines.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import ReactSwipe from 'react-swipe';


const CustomerSaying = () => {
  const reviews = [
    {
      text: "The platform is incredibly easy to use. I quickly found an expert who provided me with clear and detailed answers. Highly recommend it!",
      author: "John D"
    },
    {
      text: "The personalized service was fantastic. The expert I chose was knowledgeable and attentive, making sure all my questions were answered thoroughly. Worth every penny!",
      author: "Sarah M."
    },
    {
      text: "I was impressed by the quality of advice I received. The expert took the time to understand my needs and provided valuable insights. The pricing is also very fair.",
      author: "Emily R."
    }
  ];

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  let reactSwipeEl;


  useEffect(() => {

    setInterval(() => {
      if (reactSwipeEl) {
        reactSwipeEl.next()
      }
    }, 3000);
  }, [])
  return (
    <div>
      <div className="container customer-saying mt-5 pt-5">
        <div className="row">
          <div className="col-md-3 position-relative none-section">
            <img src={LeftLine} alt="" className='line-image-height' />
            <img src={AnimationSaying1} alt="" className='animation-saying-1' />
            <img src={AnimationSaying2} alt="" className='animation-saying-2' />
            <img src={AnimationSaying3} alt="" className='animation-saying-3' />

          </div>
          <div className="col-lg-6 col-md-12 mt-5 text-center">
            {/* <div className={` ' customer-saying-card  theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
              <h2 className={` ' why-choose-h5 pt-4  theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>See what our customer's saying</h2>
              <p className={` ' customer-saying-p pt-4  theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>The platform is incredibly easy to use. I quickly found an expert who provided me with clear and detailed answers. Highly recommend it!</p>
              <h5 className='customer-saying-william text-center pt-4'>John D</h5>
            </div> */}
            <div className={`customer-saying-card theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
              <h2 className={`why-choose-h5 pt-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>See what our customer's saying</h2>
              {/* <Swiper
                spaceBetween={50}
                slidesPerView={1}
                loop={true}
                autoplay={{ delay: 100, disableOnInteraction: false }}
                pagination={{ clickable: true  }}
              >
                {reviews.map((review, index) => (
                  <SwiperSlide key={index}>
                    <>
                      <p className={`customer-saying-p pt-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>{review.text}</p>
                      <h5 className='customer-saying-william text-center pt-4'>{review.author}</h5>
                    </>
                  </SwiperSlide>
                ))}
              </Swiper> */}
              <ReactSwipe
                className="carousel"
                swipeOptions={{ continuous: true }}
                ref={el => (reactSwipeEl = el)}
              >
                {reviews.map((review, index) => (
                  <div key={index}>
                    <p className={`customer-saying-p pt-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>{review.text}</p>
                    <h5 className='customer-saying-william text-center pt-4'>{review.author}</h5>
                  </div>
                ))}
              </ReactSwipe>

            </div>
          </div>
          <div className="col-md-3 position-relative none-section">
            <img src={RightLine} alt="" className='line-image-height' />
            <img src={AnimationSaying4} alt="" className='animation-saying-4' />
            <img src={AnimationSaying5} alt="" className='animation-saying-5' />
            <img src={AnimationSaying6} alt="" className='animation-saying-6' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerSaying;