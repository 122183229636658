import React from "react";
import logo from "../../Images/logo-mini.png";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import profile from '../../Images/Ellipse1.png';
import chatmedia from '../../Images/cat-five1.png';
import Modal from './modal'

function index() {
  
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  return (
    <div>
      <div className="container-fluid background-main pb-5">
        <div className="container">
          <h2 className="search-note-h2 pt-5">Message</h2>
          <Breadcrumbs aria-label="breadcrumb" class Name="breadcrumbs">
            <Link underline="hover" color="inherit" href="/home" className="">
              Home
            </Link>
            <Link underline="hover" color="inherit" href="/">
              User listing
            </Link>
            <Link underline="hover" color="inherit" href="/">
              User detail
            </Link>

            <Typography color="text.primary">
              {" "}
              <b>Message</b>
            </Typography>
          </Breadcrumbs>
        </div>

        <div className="search-note m-auto mt-4">
          <div className="px-4">

            <div className="message-chat ">
              <div className="message-header">
                <div className="bar-icon mt-2">
                <FontAwesomeIcon icon={faEllipsisH} />
                </div>
                <div className="chat-section pt-5">
                  <div className="client-side-chat ">
                    <div className="d-flex">
                    <span><img src={profile} alt="" className="chat-profile" /></span>
                    <span><img src={chatmedia} alt="" className="chat-media mx-3" /></span>
                    </div>  
                    <div className="chat-content p-3 mt-3">
                    Lorem ipsum dolor sit amet, consectetur scing elit ut aliquam, purus sit amet.
                    </div>

                  </div>
                  <div className="user-side-chat">

                  <div className="client-side-chat float-end mt-4">
                    <div className="d-flex user-side-chat-fixed">
                    <div className="user-chat-content p-3 mx-2">
                    Lorem ipsum dolor sit amet, consectetur scing elit ut aliquam, purus sit amet.
                    </div>
                    <span><img src={profile} alt="" className="chat-profile" /></span>
                    </div>
                   

                  </div>
                  
                  </div>
                </div>
                
              </div>
            </div>




       
            <div className="pt-5 pb-5 m-auto">
            <span className="modal-button"><Modal /></span>
              <button className="save-draft-btn mt-4">Reject</button>
              <Link to="">
                {/* <button className="search-btn mt-4">Accept</button> */}
                
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
