import React, { useEffect } from 'react';
import { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import FilterListIcon from "@mui/icons-material/FilterList";
import AgeSlider from "./AgeSlider";

const categories = {
  gender: [
    { value: "0", label: "Male" },
    { value: "1", label: "Female" },
    { value: "2", label: "TS" },
  ],
  ethnicity: [
    { value: "White", label: "White" },
    { value: "Black", label: "Black" },
    { value: "Asian", label: "Asian" },
    { value: "Indian", label: "Indian" },
    { value: "Hispanic", label: "Hispanic" },
    { value: "Middle Eastern", label: "Middle Eastern" },
    { value: "Other", label: "Other" },
  ],
  zodiac: [
    { value: "Jawan", label: "Jawan" },
    { value: "Munawwar Rangila", label: "Munawwar Rangila" },
    { value: "Salsabilla", label: "Salsabilla" },
    { value: "Now Entertainment", label: "Now Entertainment" },
  ],
  // age: [
  //   { value: "Age", label: "Age" },
  // ],
  smoke: [
    { value: "No-addition", label: "No" },
    { value: "Occasionally", label: "Occasionally" },
    { value: "Regularly-addition", label: "Regularly" },
  ],
  drink: [
    { value: "No", label: "No" },
    { value: "Socially", label: "Socially" },
    { value: "Regularly", label: "Regularly" },
  ],
  workout: [
    { value: "Yoga", label: "Yoga" },
    { value: "Long walk", label: "Long Walk" },
    { value: "Boxing", label: "Boxing" },
    { value: "Strength", label: "Strength" },
  ],
  bodytype: [
    { value: "Slim", label: "Slim" },
    { value: "Fit", label: "Fit" },
    { value: "Muscular", label: "Muscular" },
    { value: "Average", label: "Average" },
    { value: "Curvy", label: "Curvy" },
  ],
};

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255 / 100%) 0px 0px 0px 0px, rgba(0 0 0 / 5%) 0px 0px 0px 1px, rgba(0 0 0 / 10%) 0px 10px 15px -3px, rgba(0 0 0 / 5%) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const CustomizedMenus = ({ onAddRemove, handleAgeSelect, setIsUserListFilterDivPresent }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);


  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (category, value) => () => {
    console.log(selectedValues, "selectedValuesselectedValuesselectedValues")
    console.log("category", category, value)
    let filteredValue = value.endsWith('-addition') === true ? value.split('-addition')[0] : value;
    // console.log("filteredValue",filteredValue)
    // console.log("value.split('-addition')",value.split('-addition')[0])
    onAddRemove({ category, filteredValue });

    const selectedIndex = selectedValues.indexOf(value);
    console.log("selectedIndex", selectedIndex)
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedValues, value];
    } else {
      newSelected = selectedValues.filter((item) => item !== value);
    }

    setSelectedValues(newSelected);
    console.log(newSelected, "newSelectednewSelected")
  };

  const isSelected = (value) => selectedValues.indexOf(value) !== -1;

  const renderMenuItems = React.useMemo(() => (category) => {
    return categories[category].map((item) => (
      <MenuItem
        key={`${category}-${item.value}`}
        onClick={handleMenuItemClick(category, item.value)}
        disableRipple
      >
        <ListItemIcon>
          <Checkbox checked={isSelected(item.value)} className="0" />
        </ListItemIcon>
        <span className={` ' mt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white-light' : 'light-theme'}`}>{item.label}</span>
      </MenuItem>
    ));
  }, [categories, handleMenuItemClick, isSelected]);

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  useEffect(() => {
    let isLength = selectedValues && selectedValues.length > 0 
    setIsUserListFilterDivPresent(isLength)
  }, [selectedValues])

  // console.log("selectedValues", selectedValues);
  return (

    <div id="user-list-filter-div" className={`theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
      <TextField
        fullWidth
        value={selectedValues.length > 0 ? `${selectedValues.length} selected` : "Filter by"}
        className={`theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <Button
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <FilterListIcon className={`theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`} />
            </Button>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
          },
        }}
        className={`theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}
      />
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}

      >{selectedValues && selectedValues.length > 0
        ? <div className='p-3 d-flex justify-content-center' sty>
          <button className={`bg-black px-4 py-2 rounded-5 text-bg-dark theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
            onClick={() => window.location.reload()}
          >Clear filter</button>
        </div> : ''}
        <div className={` '  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          <p className={` ' p-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            <b>Gender</b>
          </p>
          {renderMenuItems("gender")}
        </div>
        <div className={` '  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          <p className={` ' p-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            <b>Select Age</b>
            <AgeSlider handleAgeSelect={handleAgeSelect} />
          </p>

        </div>
        <div className={` '  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          <p className={` ' p-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            <b>Ethnicity</b>
          </p>
          {renderMenuItems("ethnicity")}
        </div>
        <div className={` '  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          <p className={` ' p-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            <b>zodiac</b>
          </p>
          {renderMenuItems("zodiac")}
        </div>

        {/* <div>
          <p  className={` ' p-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>
            <b>Age</b>
          </p>
          {renderMenuItems("age")}
        </div> */}

        <div className={` '  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          <p className={` ' p-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            <b>Smoking</b>
          </p>
          {renderMenuItems("smoke")}
        </div>

        <div className={` '  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          <p className={` ' p-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            <b>Drinking</b>
          </p>
          {renderMenuItems("drink")}
        </div>

        <div className={` '  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          <p className={` ' p-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            <b>Workout</b>
          </p>
          {renderMenuItems("workout")}
        </div>

        <div className={` '  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          <p className={` ' p-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            <b>Body Type</b>
          </p>
          {renderMenuItems("bodytype")}
        </div>

      </StyledMenu>
    </div>
  );
};

export default CustomizedMenus;
