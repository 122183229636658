import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

function valuetext(value) {
  return `${value}°C`;
}

export default function RangeSlider({ handleAgeSelect }) {
  const [value, setValue] = React.useState([18, 72]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleAgeSelect(newValue);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} className="pt-4">
      <Typography sx={{ minWidth: 35 }}>18</Typography>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        min={18}
        max={72}
        onChange={handleChange}
        valueLabelDisplay="on"
        getAriaValueText={valuetext}
        sx={{
          color: 'pink',
          '& .MuiSlider-thumb': {
            backgroundColor: '#E60B82',
          },
          '& .MuiSlider-track': {
            backgroundColor: '#E60B82',
          },
          '& .MuiSlider-rail': {
            backgroundColor: '#E60B82',
          },
        }}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Typography>{72}</Typography>
    </Box>
  );
}
