import { useEffect, useContext, useState } from "react";
import {
  socket,
  socketMessageContext,
  SocketMessageProvider,
} from "./context/SocketMessageContext";

import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";

import Home from "./component/Home-Section";
import NavbarLogout from "./component/Home-Section/NavbarLogout";
import NavbarSignFlow from "./component/Login/NavbarSignFlow";
import WhyChoose from "./component/Home-Section/WhyChoose";
import TrustedUs from "./component/Home-Section/TrustedUs";
import ReadOurBlog from "./component/Home-Section/ReadOurBlog";
import CustomerSaying from "./component/Home-Section/CustomerSaying";
import Subscribe from "./component/Home-Section/Subscribe";
import GotoHomeModal from "./component/Home-Section/GotoHomeModal";

import Navbar from "./component/Navbar";
import Footer from "./component/Footer";

import DarkTheme from "./component/DarkTheme";

// loaders
import Loaders from "./component/Loaders/index";

// Login
import Login from "./component/Login/index";
import Forgot from "./component/Login/Forgot-Passsword/index";
import OTPVerification from "./component/Login/OTP-Verification/index";
import SetNewPassword from "./component/Login/Set-New-Password/index";
import OTPDigit from "./component/Login/OTP-Verification/otpdigit";
import SignUp from "./component/Login/Sign-Up/index";
import Createprofile from "./component/Login/Create-profile/index";
import Range from "./component/Login/Create-profile/range";

// SearchNote
import SearchNote from "./component/Search-Note/index";
import Userslist from "./component/Search-Note/Userslist";
import HomeCard from "./component/Search-Note/HomeCard";
import UserDetail from "./component/Search-Note/UserDetail";
import Message from "./component/Search-Note/Message";
import Modal from "./component/Search-Note/modal";
import MessageSend from "./component/Search-Note/MessageSend";
import ContinueChatModal from "./component/Search-Note/ContinueChatModal";
import Profile from "./component/Profile/index";
import Overview from "./component/Profile/Overview";
import EditProfileModal from "./component/Profile/EditProfileModal";
import ChangePasswordModal from "./component/Profile/ChangePasswordModal";
import ChangePhoneModal from "./component/Profile/ChangePhoneModal";
import EnterOTP from "./component/Profile/EnterOTP";

// InboxChat
import InboxMessage from "./component/Search-Note/InboxChat/Index";
import JobCreatedDetails from "./component/Search-Note/InboxChat/JobCreatedDetails";

// AcceptReject
import AcceptReject from "./component/Search-Note/AcceptReject/index";

// Profile-Wallet
import Wallet from "./component/Profile/Wallet/index";

// Draft List
import DraftList from "./component/Profile/DraftList/index";

// Contact Us
import ContactUs from "./component/Profile/ContactUs/index";
import Contracts from "./component/Profile/ContactUs/Contracts";

// All Contracts
import AllContracts from "./component/Profile/AllContracts/index";
import NavSection from "./component/Profile/AllContracts/NavSection";
import ViewContract from "./component/Profile/AllContracts/ViewContract";
import Review from "./component/Profile/AllContracts/Review";
import AddDispute from "./component/Profile/AllContracts/AddDispute";

// All Disputes
import AllDisputes from "./component/Profile/AllDisputes/index";
import NavSectionDisputes from "./component/Profile/AllDisputes/NavSection";

// Transection History
import TransectionHistory from "./component/Profile/TransectionHistory/index";

// My Earning
import MyEarning from "./component/Profile/MyEarning/index";
import Chart from "./component/Profile/MyEarning/chart";
import WithdrawAmountModal from "./component/Profile/MyEarning/WithdrawAmountModal";
import RequestSuccessfullyModal from "./component/Profile/MyEarning/RequestSuccessfullyModal";

// Logout

import Logout from "./component/Profile/Logout/index";

// Identification
import Identification from "./component/Profile/Identification/index";
import UploadModal from "./component/Profile/Identification/UploadModal";
import ReviewingVideo from "./component/Profile/Identification/ReviewingVideo";
import Notifications from "./component/Notifications";
import { connectSocket } from "./socket/NotificationSocket";
import ReferButton from "./component/Search-Note/AcceptReject/ReferButton";

// About us
import AboutUs from "./component/Profile/AboutUs/AboutUs";
import PrivacyPolicy from "./component/Profile/AboutUs/PrivacyPolicy";
import TermsConditions from "./component/Profile/AboutUs/TermsConditions";
import FAQ from "./component/Profile/AboutUs/FAQ";
import HowItWorks from "./component/Profile/AboutUs/HowItWorks";
import BlogDetails from "./component/Profile/AboutUs/BlogDetails";
import Blog from "./component/Profile/AboutUs/Blog";

function App() {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const location = useLocation();
  const navigate = useNavigate();
  // console.log(location);
  const loginPaths = [
    "/login",
    "/otp-verification",
    "/forgot-password",
    "/set-password",
    "/sign-up",
    "/create-profile",
    "/about-us",
    "/privacy-policy",
    "/terms-conditions",
    "/faq",
    "/blog",
    "/blog-details",
    "/how-it-works",
  ];
  const isLoginPage =
    loginPaths.includes(location.pathname) ||
    location.pathname.includes("/otp-verification") ||
    location.pathname.includes("/otp-verification-mail") ||
    location.pathname.includes("/otp-verification-phone") ||
    location.pathname.includes("/otp-verification-change-change");

  const { handleMessageSetter } = useContext(socketMessageContext);

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        console.log("Socket.IO connected", socket);
      });
      if (JSON.parse(localStorage.getItem(`user`))) {
        // console.log('workinggggg')
        socket.emit("addUser", JSON.parse(localStorage.getItem(`user`))._id);

        socket.on("message", (data) => {});
        if (JSON.parse(localStorage.getItem("user"))._id) {
          socket.emit("getUserRealtimeMessages", {
            userId: JSON.parse(localStorage.getItem("user"))._id,
          });

          socket.on("getUserRealtimeMessages", (msg) => {
            // console.log("Received message Ib hereeeeeee APP JS--------------------->>>>>>>>>>>>>>>>>>>>:", msg);

            if (msg && Object.keys(msg).length == 0) {
              socket.emit("getUserRealtimeMessages", {
                userId: JSON.parse(localStorage.getItem("user"))._id,
              });
              socket.on("getUserRealtimeMessages", () => {});
            }
          });
        }
      }
    }
  }, [socket]);

  console.log(`isLoginPage`, isLoginPage);
  window.scrollTo(0, 0);

  if (
    !isLoginPage &&
    window.location.pathname.toLocaleLowerCase() != "/home" &&
    !localStorage.getItem("user")
  ) {
    window.location.href = "/home";
  }

  console.log("window.location.pathname", window.location.pathname);

  const checkNavShow = () => {
    if (
      window.location.pathname.toLocaleLowerCase() == "/about-us" ||
      window.location.pathname.toLocaleLowerCase() == "/privacy-policy" ||
      window.location.pathname.toLocaleLowerCase() == "/terms-conditions" ||
      window.location.pathname.toLocaleLowerCase() == "/faq" ||
      window.location.pathname.toLocaleLowerCase() == "/blog" ||
      window.location.pathname.toLocaleLowerCase() == "/how-it-works"
      
    ) {
      return true;
    }
    return false;
  };
  return (
    <div className={`app ${theme}-mode`}>
      {/* {loginPaths ? (
        ""
      ) : ( */}
      <SocketMessageProvider>
        {!isLoginPage ? (
          window.location.pathname.toLocaleLowerCase() == "/home" ? (
            !localStorage.getItem("user") ? (
              <NavbarLogout />
            ) : (
              <Navbar />
            )
          ) : (
            <Navbar />
          )
        ) : checkNavShow() == true ? (
          !localStorage.getItem("user") ? (
            <NavbarLogout />
          ) : (
            <Navbar />
          )
        ) : (
          ""
        )}
        <Routes>
          {/* Home */}
          {/* <Route path="/socket" element={< />} /> */}
          <Route path="/home" element={<Home />} />
          {/* Loaders */}
          {/* <Route path="" element={<Loaders />} /> */}
          <Route path="/" element={<Navigate to="/sign-up" replace />} />
          <Route path="/" element={<NavbarLogout />} />
          <Route path="/" element={<NavbarSignFlow />} />
          <Route path="/" element={<WhyChoose />} />
          <Route path="/" element={<TrustedUs />} />
          <Route path="/" element={<ReadOurBlog />} />
          <Route path="/" element={<CustomerSaying />} />
          <Route path="/" element={<Subscribe />} />
          <Route path="/" element={<GotoHomeModal />} />

          {/* Login */}
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/otp-verification" element={<OTPVerification />} />
          <Route
            path="/otp-verification-mail/:cred"
            element={<OTPVerification />}
          />
          <Route
            path="/otp-verification-phone/:cred"
            element={<OTPVerification />}
          />
          <Route
            path="/otp-verification-change-change/:cred"
            element={<OTPVerification />}
          />
          <Route path="/set-password" element={<SetNewPassword />} />
          <Route path="/" element={<OTPDigit />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/create-profile" element={<Createprofile />} />
          <Route path="/range" element={<Range />} />
          {/* SearchNote */}
          <Route path="/search-note" element={<SearchNote />} />
          <Route path="/search-note/:id" element={<SearchNote />} />
          <Route path="/user-list" element={<Userslist />} />
          <Route path="/user-list/:id" element={<Userslist />} />
          <Route path="/user-list-refer" element={<Userslist />} />
          <Route path="/user-list-refer/:senderId" element={<Userslist />} />
          <Route
            path="/user-list-refer/:id/:senderId"
            element={<Userslist />}
          />
          <Route path="/home-card" element={<HomeCard />} />
          <Route path="/user-detail/:id/:nid" element={<UserDetail />} />
          <Route path="/user-detail-refer/:id" element={<UserDetail />} />
          <Route path="/user-detail/:id" element={<UserDetail />} />
          <Route path="/message" element={<Message />} />
          <Route path="/modal" element={<Modal />} />
          <Route path="/message-send" element={<MessageSend />} />
          <Route path="/message-send/:msgId" element={<MessageSend />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/edit-profile-modal" element={<EditProfileModal />} />
          <Route path="/" element={<ChangePasswordModal />} />
          <Route path="/" element={<ChangePhoneModal />} />
          <Route path="/" element={<EnterOTP />} />
          {/* InboxChat */}
          <Route path="/inbox-message" element={<InboxMessage />} />
          <Route path="/job-created/:id" element={<JobCreatedDetails />} />

          {/* AcceptReject */}
          <Route path="/accept-reject" element={<AcceptReject />} />
          <Route path="/accept-reject/:id" element={<AcceptReject />} />
          <Route path="/refer-button" element={<ReferButton />} />
          {/* <Route path="/accept-reject/:id/:nid" element={<AcceptReject />} /> */}

          {/* Profile-Wallet */}
          <Route path="/" element={<Wallet />} />
          {/* Draft List */}
          <Route path="/" element={<DraftList />} />
          {/* Contact Us */}
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/contracts" element={<Contracts />} />
          {/* All Contracts */}
          <Route path="/" element={<AllContracts />} />
          <Route path="/" element={<NavSection />} />
          <Route path="/view-contract" element={<ViewContract />} />
          <Route path="/view-contract/:id" element={<ViewContract />} />
          <Route path="/view-contract/:id/:uid" element={<ViewContract />} />

          <Route path="/" element={<Review />} />
          <Route path="/" element={<AddDispute />} />
          {/* All Disputes */}
          <Route path="/" element={<AllDisputes />} />
          <Route path="/" element={<NavSectionDisputes />} />
          {/* Transection History */}
          <Route path="/" element={<TransectionHistory />} />
          {/* Notifications */}
          <Route path="/notifications" element={<Notifications />} />
          {/* Transection History */}
          <Route path="/" element={<MyEarning />} />
          <Route path="/" element={<Chart />} />
          <Route path="/" element={<WithdrawAmountModal />} />
          <Route path="/" element={<RequestSuccessfullyModal />} />
          {/* Logout */}
          <Route path="/" element={<Logout />} />
          {/* Identification */}
          <Route path="/" element={<Identification />} />
          <Route path="/" element={<UploadModal />} />
          <Route path="/" element={<ReviewingVideo />} />
          <Route path="/dark-theme" element={<DarkTheme />} />

          {/* about us */}
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/blog" element={<Blog />} />
        </Routes>
        {/* <Footer />   */}
        {!isLoginPage && <Footer />}
      </SocketMessageProvider>
      {/* // )} */}
    </div>
  );
}

export default App;
