import React, {  useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import xmark from '../../../Images/x-mark.png';
import { createReview } from '../../../services';
import { notifyError, notifySuccess } from '../../../toastNotifications';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ msgId, userId }) {
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = useState('');
  const [value, setValue] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false); setValue(0) }

  const handleAddReview = async () => {
    if (!comment || comment.trim() === "") {
      notifyError("Comment required");
      return
    }
    let data = {
      messageId: msgId,
      userId: userId,
      comment: comment,
      starCount: value
    }

    try {
      const response = await createReview(data);
      console.log("createReview response is here -->", response);
      if (response.statusCode === 200) {
        notifySuccess(response.message);
      }
    } catch (error) {
      console.error("Error in createReview :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`)
    } finally {
      handleClose();
    }
  }
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);



  return (
    <div>
      <button onClick={handleOpen} className='px-3'>Add Review</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}  className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
            }`}>
          <img src={xmark} alt="" className='x-mark' onClick={handleClose} /> {/* Add onClick handler here */}
          <h5 className='text-center'>Review </h5>

          <Form.Label htmlFor="inputPasswordOld" className={` ' form-label-dark mt-2 theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
            }`}>Add review</Form.Label> <br />

          <Rating
            name="half-rating"
            value={value}
            precision={0.5}
            className="mt-0"
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
           
            
          />
          <br />

          <Form.Label htmlFor="inputComment" className={` ' form-label-dark mt-3 theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
            }`}>Tell us about your trip</Form.Label>
          <div className="password-input-container">
            <Form.Control
              type="text"
              id="inputComment"
              aria-describedby="passwordHelpBlockOld"
              placeholder='Write your review here'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className={` ' form-input mt-1 theme-switcher ${theme === "dark" ? "dark-theme-only-color " : "light-theme"
              }`}
            />

            <button className="search-btn w-100 mt-3" onClick={handleAddReview}>Add review</button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
