import React, { useContext, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import image from '../../Images/Group1686558039.png'
import { Link } from "react-router-dom";
import xmark from '../../Images/x-mark.png';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Import the specific icon from the free-regular-svg-icons package
import { faComments } from '@fortawesome/free-regular-svg-icons';
import { continueChat, profileComplete } from '../../services';
import { notifyError, notifySuccess } from '../../toastNotifications';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export default function BasicModal({ fixedAmount, title, hoursPrice, name, msgId, userId, endTime, startTimer }) {
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState(hoursPrice);
  const [timeLeft, setTimeLeft] = useState("");
  // const [showTitle, setShowTitle] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    // setShowTitle(true); // Reset showTitle when closing
    setOpen(false);
  };
  const handleContinueChat = async () => {
    if (msgId && userId) {
      let data = {
        "messageId": msgId,
        "userId": userId,
        "amount": amount
      }
      try {
        const response = await continueChat(data);
        console.log("continueChat response is here -->", response);
        if (response.statusCode === 200) {
          notifySuccess(response.message);
          // setShowTitle(false); // Hide
          startTimer(); // Start the timer after successful paymen
          window.location.reload();
        }
      } catch (error) {
        console.error("Error in continueChat :", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
      } finally {
        handleClose();
      }
    } else {
      notifyError(`Something went wrong`);
      handleClose();
    }
  }
  const updateHourlyPrice = async () => {
    if (amount && amount > 10000000) {
      notifyError("You can set the hourly price up to 10,000,000 dollars only.");
      setAmount(0);
      return;
    } else if (amount && amount > 0) {
      const newForm = new FormData();
      newForm.append("hoursPrice", amount);
      try {
        const response = await profileComplete(newForm);
        console.log("profileComplete response ==>>", response);
        if (response.statusCode === 200) {
          notifySuccess(response.message);
        }
      } catch (error) {
        console.error("Error uploading photo:", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
      } finally {
        handleClose();
      }
    } else {
      notifyError("Empty value not allowed");
      setAmount(0);
    }
  }
  // const startTimer = () => {
  //   // Example calculation, adjust as per your requirement
  //   setEndTime(new Date(Date.now() + hoursPrice * 60 * 60 * 1000));
  // };

  const calculateTimeLeft = (endTime) => {
    const endDate = new Date(endTime);
    const now = new Date();
    const difference = endDate - now;
    let timeLeft = "";
    if (difference > 0) {
      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      timeLeft = `${hours}h ${minutes}m ${seconds}s`;
    } else {
      timeLeft = "Expired";
    }
    return timeLeft;
  };
  useEffect(() => {
    if (endTime) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000); // Update every second
      return () => clearInterval(timer);
    }
  }, [endTime]);

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <div>
      <center className='pb-4'>
        <button className='payment-btn mt-4 ' onClick={handleOpen}>
          <FontAwesomeIcon icon={faComments} className='mr-2' />
          {title || `Continue chat`}
        </button>
      </center>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={` inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
          <img src={xmark} alt="" className='x-mark' onClick={handleClose} /> {/* Add onClick handler here */}
          <h5 className='text-center'><b>{title || `Continue chat`}</b></h5>
          {/*{showTitle && <h5 className='text-center'>Continue chat</h5>}  Conditionally render the title */}
          <Form.Label
            htmlFor="inputPasswordOld"
            className={`form-label-dark mt-3 w-100 text-center theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}
            style={{ fontWeight: 400 }}>
            {
  title
    ? (
      <span>
        Your current hourly price is <b>${hoursPrice}</b>. If you want to update your hourly price, you can update it.
      </span>
    )
    : (
      <p className="text-center">
        You need to pay <b>${hoursPrice}</b> for further assistance to <b>{name}</b>.
      </p>
    )
}
</Form.Label>
          <div className="password-input-container">
            <Form.Control
              type="number"
              id="inputPasswordOld"
              aria-describedby="passwordHelpBlockOld"
              className={`form-input mt-1 text-center amount-bar-square theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
              value={amount}
              disabled={fixedAmount}
              onChange={(e) => setAmount(e.target.value)}
              onWheel={(e) => e.target.blur()}
              min={1}
            />
            {title
              ? <button className="search-btn w-100 mt-3" onClick={updateHourlyPrice}>update</button>
              : <button className="search-btn w-100 mt-3" onClick={handleContinueChat}>Pay</button>
            }
          </div>
        </Box>
      </Modal>
    </div>
  );
}