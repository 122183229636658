import React, { useEffect } from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import xmark from "../../../Images/x-mark.png";
import money1 from "../../../Images/money1.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Identification from "../../../Images/bag-icon.png";
import VideoIdentification from '../../../Images/timmer.png';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
    useEffect(() => {
      const storedTheme = localStorage.getItem('theme');
      if (storedTheme) {
        setTheme(storedTheme);
      }
    }, []);
  
    useEffect(() => {
      localStorage.setItem('theme', theme);
    }, [theme]);

  return (
    <div>
      
      <button className="logout-btn mx-2 px-5 py-2 w-100" onClick={handleOpen}>Upload</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme' }`}>
          <img src={xmark} alt="" className="x-mark" onClick={handleClose} />{" "}
          {/* Add onClick handler here */}
          <center>{/* <img src={money1} alt="" /> */}</center>
          <h5 className="text-center">Video Identification </h5>
          <img src={VideoIdentification} alt="" className="m-auto mt-4 mb-4" />
          <p className={` ' text-center pt-2 round-bold text-dark theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>
          We’re reviewing your video
          </p>
          
          <p className="text-center pt-2">
          This make take up to 48 minutes, so feel free to continue using ASK human app. we’ll notify you when the review is finished 
          </p>
          <center className="mt-4">
            
            <button className="logout-btn mx-2 w-100">Done</button>
          </center>
        </Box>
      </Modal>
    </div>
  );
}
