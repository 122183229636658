import React, { useEffect, useState } from "react";
import logo from "../../../Images/logo-mini.png";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import verified from "../../../Images/verified.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhotoFilm,
  faEllipsisH,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import { Link, useParams, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { List, ListItem, Popover } from "@mui/material";
import profile from "../../../Images/Ellipse1.png";
import chatmedia from "../../../Images/cat-five1.png";
import Popupmsg from ".././InboxChat/PopupMsg";
import {
  acceptOrRejectOrEndInvitation,
  applyInvitation,
  invitationDetails,
  referInvitatationAcceptReject,
} from "../../../services";
import { notifyError, notifySuccess } from "../../../toastNotifications";
import xmark from "../../../Images/x-mark.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  socket,
  socketMessageContext,
} from "../../../context/SocketMessageContext";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '30rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Index() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [contractData, setContractData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [applyModelOpen, setApplyModelOpen] = useState(false);
  const [proposalMessage, setProposalMessage] = useState("");

  const [selectedMediaArr, setSelectedMediaArr] = useState([]);

  const [rejectConfirmModalOpen, setRejectConfirmModalOpen] = useState(false);

  const [isVerified, setIsVerified] = useState(0);


  const handleOpenRejectConfirmModal = () => {
    setRejectConfirmModalOpen(true);
  };

  const handleCloseRejectConfirmModal = () => {
    setRejectConfirmModalOpen(false);
  };

  const handleApplyModelOpen = () => {
    setApplyModelOpen(true);
  };

  const handleApplyModelClose = () => {
    setApplyModelOpen(false);
    setIsLoading(false);
  };

  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const openPopover = Boolean(popoverAnchorEl);

  const togglePopupMsg = () => {
    setShowPopupMsg((prev) => !prev);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleAcceptOrRejectInvite = async (isStatus) => {
    let data = {
      messageId: id,
      isStatus,
    };
    try {
      const response = await acceptOrRejectOrEndInvitation(data);
      console.log(
        "acceptOrRejectOrEndInvitation response is here -->",
        response
      );
      if (response.statusCode === 200) {
        notifySuccess(response.message);
        navigate(`/message-send/${id}`);
      }
    } catch (error) {
      console.error("Error in acceptOrRejectOrEndInvitation :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    } finally {
      handleCloseRejectConfirmModal();
    }
  };

  const handleAcceptOrRejectPerPosal = async (isStatus) => {
    console.log(JSON.parse(localStorage.getItem("user"))._id);
    let data = {
      notesId: contractData?.message?.notesId?._id || "",
      userId: contractData?.message?.recipient?._id || "",
      status: isStatus,
    };
    try {
      const response = await referInvitatationAcceptReject(data);
      console.log(
        "referInvitatationAcceptReject response is here -->",
        response
      );
      if (response.statusCode === 200) {
        notifySuccess(response.message);
        navigate(`/message-send/${id}`);
      }
    } catch (error) {
      console.error("Error in referInvitatationAcceptReject :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    }
  };

  const checkUrlType = async (url) => {
    const extension = url.split(".").pop().toLowerCase();

    // Check the file extension
    if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "gif" ||
      extension === "bmp"
    ) {
      return "image";
    } else if (
      extension === "mp4" ||
      extension === "avi" ||
      extension === "mov" ||
      extension === "mkv" ||
      extension === "webm"
    ) {
      return "video";
    } else {
      return "unknown";
    }
  };

  const getInvitationDetails = async () => {
    let data = {
      messageId: id,
    };
    try {
      const response = await invitationDetails(data);
      console.log("invitationDetails response is here -->", response);
      if (response.statusCode === 200) {
        setContractData(response.data);
        let media = response?.data?.message?.messageList?.[0]?.media || [];
        let arr = [];

        for (let i = 0; i < media.length; i++) {
          const element = media[i];
          console.log("element is here -->", element);
          arr.push({
            key: arr.length,
            value: element,
            type: await checkUrlType(element),
          });
        }
        setSelectedMediaArr(arr);

        if (
          response?.data?.message?.isReferedStatus == 2 &&
          response?.data?.message?.isStatus == 1
        ) {
          navigate(`/message-send/${id}`);
        }
      }
    } catch (error) {
      console.error("Error in invitationDetails :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
      navigate(`/profile`);
    }
  };

  const handleApplyOnrefferedInvite = async () => {
    setIsLoading(true);
    if (!proposalMessage) {
      notifyError(`Please type your proposal message`);
      setIsLoading(false);
    } else {
      let data = {
        messageId: id,
        notesId: contractData?.message?.notesId?._id || "",
        message: proposalMessage.trim(),
      };
      try {
        const response = await applyInvitation(data);
        console.log("applyInvitation response is here -->", response);
        if (response.statusCode === 200) {
          notifySuccess(response.message);
          navigate(`/search-note`);
        }
      } catch (error) {
        console.error("Error in applyInvitation :", error);
        notifyError(error?.response?.data?.message || `Something went wrong`);
      } finally {
        handleApplyModelClose();
      }
    }
  };

  useEffect(() => {
    if (id) {
      getInvitationDetails();
    }
  }, []);

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const [selectedMedia, setSelectedMedia] = useState(null);
  const [showMediaModal, setShowMediaModal] = useState(false);

  const handleMediaClick = (media) => {
    setSelectedMedia(media);
    setShowMediaModal(true);
  };

  const handleCloseMediaModal = () => {
    setShowMediaModal(false);
    setSelectedMedia(null);
  };
  const timeFormatter = (time) => {
    let now = new Date(time);
    now = now.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return now;
  };

  const actionTaken = {
    backgroundColor: `#f2f2f2`,
    borderRadius: `10px`,
    border: `2px solid #dbe8ed`
  };



  useEffect(() => {
    if (id && JSON.parse(localStorage.getItem(`user`))._id) {
      let data = {
        messageId: id,
        userId: JSON.parse(localStorage.getItem(`user`))._id,
      };
      socket.emit("getMessage", data);
      socket.on("getMessage", (msg) => {
        console.log("MESSAAGAEE=======>>>>>>>>>>>>>", msg?.recipients?.videoVerify)
        setIsVerified(msg?.recipients?.videoVerify)
      })
    }
  }, [id, socket]);


  // if (msgId) {
  //   socket.on("getMessage", (msg) => {

  // useEffect(() => {
  //   if (id) {
  //     socket.on("getMessage", (msg) => {
  //       console.log("MESSAAGAEE=======>>>>>>>>>>>>>", msg)
  //     })
  //   }
  // }, [id, socket]);


  return (
    <div>
      <div
        className={` ' container-fluid background-main pb-5 theme-switcher ${theme === "dark" ? "black-color" : "light-theme"
          }`}
      >
        <div className="container">
          <h2 className="search-note-h2 pt-5">Message</h2>
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
            <Link
              underline="hover"
              color="inherit"
              to="/home"
              className={` ' theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                }`}
            >
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              to="/user-list"
              className={` ' theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                }`}
            >
              User listing
            </Link>
            {/* <Link
              underline="hover"
              color="inherit"
              to="/user-detail"
              className={` ' theme-switcher ${
                theme === "dark" ? "dark-theme-only-white" : "light-theme"
              }`}
            >
              User detail
            </Link> */}
            <Typography
              color="text.primary"
              className={` ' theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                }`}
            >
              <b>Message</b>
            </Typography>
          </Breadcrumbs>
        </div>

        <div
          className={` ' search-note m-auto mt-4 theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
            }`}
        >
          <div className="px-4">
            <div className="message-chat">
              <div className="message-header">
                {/* <div className="bar-icon mt-2" onClick={handlePopoverOpen}>
                  <FontAwesomeIcon icon={faEllipsisH} />
                </div> */}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  size="2x"
                  className="float-end mt-2 me-2 perposal-info"
                  onClick={togglePopupMsg}
                />
                {showPopupMsg &&




                  // <Popupmsg />



                  <div className={`'mt-4 pt-5 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
                    <div
                      className={`popup-msg popup-msg p-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                      style={actionTaken}
                    >
                      <div className={`'row theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
                        <div className="d-flex justify-content-center" style={{ color: '#02a0e3', cursor: 'pointer' }}><h5>Referrred By</h5></div>

                        <div className="d-flex">
                          <img src={contractData?.message?.recipient?.profileImage} alt="" className="popup-msg-user-profile object-fit-cover" style={{ width: '70px' }} />
                          <span className='w-100'>
                            {" "}
                            <div className='d-flex justify-content-between w-100 align-items-center'>
                              <h5 className=" d-flex mt-2 ms-2">
                                {contractData?.message?.recipient?.name}{" "}
                                <span>
                                  {" "}
                                  {isVerified == 1 ?
                                    <img src={verified} alt="" className="mt-1 ms-2" />
                                    : ''}
                                </span>
                              </h5>
                            </div>

                            <div className="">
                              <p className="ms-2" style={{ color: '#797c7b' }}>
                                {contractData?.message?.recipient?.about.substring(0, 180)}...
                              </p>
                            </div>
                          </span>

                        </div>
                      </div>

                    </div>
                  </div >


                }
                {/* <Popover
                  open={openPopover}
                  anchorEl={popoverAnchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <List>
                    <ListItem button onClick={handlePopoverClose}>
                      Add Dispute
                    </ListItem>
                    <ListItem button onClick={handlePopoverClose}>
                      End Contract
                    </ListItem>
                  </List>
                </Popover> */}

                <div className="chat-section pt-5 mb-5">
                  <div className="client-side-chat">
                    <div className="d-flex w-100">
                      <span style={{ minWidth: `50px` }}>
                        <img
                          src={
                            contractData?.message?.sender?.profileImage || ""
                          }
                          alt="pr"
                          className="chat-profile object-fit-cover"
                        />
                      </span>
                      <div className="d-flex w-100 flex-wrap">
                        {selectedMediaArr &&
                          selectedMediaArr.map((val, index) => {
                            return (
                              <div
                                className="m-2"
                                style={{
                                  width: "9rem",
                                  minWidth: "9rem",
                                  height: "8rem",
                                  position: "relative",
                                }}
                                key={index}
                                onClick={() => handleMediaClick(val)}
                              >
                                {val.type === "image" ? (
                                  <img
                                    src={val.value}
                                    alt="Preview"
                                    className="object-fit-cover"
                                    style={{
                                      borderRadius: "10px",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                ) : (
                                  <video
                                    controls
                                    className="object-fit-cover"
                                    style={{
                                      borderRadius: "10px",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  >
                                    <source src={val.value} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                              </div>
                            );
                          })}
                      </div>
                      {/* <span>
                        <img src={contractData?.message?.messageList?.[0]?.media?.[0] || chatmedia} alt="" className="chat-media mx-3" />
                      </span> */}
                    </div>
                    {contractData?.message?.messageList?.[0]?.message && (
                      <div
                        className={`user-chat-content mt-2 p-3 mr-3 theme-switcher ${theme === "dark" ? "message-bar-color" : "light-theme"
                          }`}
                      >
                        <div className="user-chat-name"><b>{contractData?.message?.sender?.name}</b></div>
                        {/* <span>{contractData?.message?.sender?.name}</span> */}
                        {/* <span>{contractData?.message?.messageList?.[0]?.createdAt}</span> */}
                        {contractData?.message?.messageList?.[0]?.message}
                        <div className="message-time-left">
                          {contractData?.message?.messageList?.length > 0 ?
                            timeFormatter(contractData.message.messageList[0].createdAt) :
                            'No message available'}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className="user-side-chat">
                    <div className="client-side-chat float-end mt-4">
                      <div className="d-flex user-side-chat-fixed">
                        <div className="user-chat-content p-3 mx-2">
                          Lorem ipsum dolor sit amet, consectetur scing elit ut aliquam, purus sit amet.
                        </div>
                        <span>
                          <img src={profile} alt="" className="chat-profile" />
                        </span>
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* <MsgBar /> */}
                {!contractData?.message?.isRefered ? (
                  contractData?.message?.isReferedStatus == 1 ? (
                    <div className="m-auto pb-4 d-flex flex-wrap justify-content-between">
                      <button
                        className="save-draft-btn mt-4"
                        onClick={() => handleAcceptOrRejectPerPosal("3")}
                      >
                        Reject
                      </button>
                      <span className="modal-button">
                        <button
                          className="perposal-accept-reject-btn"
                          onClick={() => handleAcceptOrRejectPerPosal("2")}
                        >
                          Accept
                        </button>
                      </span>
                    </div>
                  ) : (
                    <div className="m-auto pb-4 d-flex flex-wrap justify-content-between">
                      <button
                        className="save-draft-btn mt-4"
                        onClick={handleOpenRejectConfirmModal}
                      >
                        Reject
                      </button>
                      <span className="modal-button">
                        <button
                          className="perposal-accept-reject-btn"
                          onClick={() => handleAcceptOrRejectInvite("1")}
                        >
                          Accept
                        </button>
                      </span>
                      <button
                        className="save-draft-btn mt-4"
                        style={{ backgroundColor: "#02a0e3" }}
                        onClick={() =>
                          navigate(
                            `/user-list-refer/${contractData?.message?.notesId?._id}/${contractData?.message?.sender?._id}`
                          )
                        }
                      >
                        Refer
                      </button>
                    </div>
                  )
                ) : contractData?.message?.isReferedStatus == 1 ? (
                  <div className="m-auto pb-4 d-flex flex-wrap justify-content-center">
                    <button className="save-draft-btn mt-4">Applied</button>
                  </div>
                ) : (
                  <div className="m-auto pb-4 d-flex flex-wrap justify-content-center">
                    <button
                      className="perposal-accept-reject-btn"
                      onClick={handleApplyModelOpen}
                    >
                      Apply
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal
          open={applyModelOpen}
          onClose={handleApplyModelClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={` ' inner-shadow p-0 border-0 border-rduis theme-switcher ${theme === "dark" ? "black-color" : "light-theme"
            }`}>
            <img
              src={xmark}
              alt=""
              className="x-mark m-3 position-absolute right-0"
              onClick={handleApplyModelClose}
            />{" "}
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="are-you-sure-heading p-3"
            >
              Message
            </Typography>
            <Typography id="modal-modal-description" sx={{ m: 2 }}>
              <p className="modal-description-p"> </p>
              <Form>
                <Form.Group
                  className="mb-3 mt-4"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className={` ' theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                    }`}>Message</Form.Label>

                  <Form.Control
                    as="textarea"
                    value={proposalMessage}
                    onChange={(e) => setProposalMessage(e.target.value)}
                    rows={3}
                    placeholder="Type a message.."
                    className={` '  theme-switcher ${theme === "dark" ? "bar-icon-set" : "light-theme"
                      }`}
                  />
                </Form.Group>
              </Form>
            </Typography>
            <div className="pt-4 pb-5 m-auto mx-4">
              <button className="save-draft-btn mt-4 border-1">Cancel</button>
              {/* <Link to="/message-send"> */}
              {isLoading ? (
                <button className="search-btn mt-4">Sending...</button>
              ) : (
                <button
                  className="search-btn mt-4"
                  onClick={handleApplyOnrefferedInvite}
                >
                  Send
                </button>
              )}
              {/* </Link> */}
            </div>
          </Box>
        </Modal>
      </div>

      <div>
        <Modal
          open={rejectConfirmModalOpen}
          onClose={handleCloseRejectConfirmModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}

            className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === "dark" ? "black-color" : "light-theme"
              }`}
          >
            <img
              src={xmark}
              alt=""
              className="x-mark"
              onClick={handleCloseRejectConfirmModal}
            />{" "}
            {/* Add onClick handler here */}
            <center>{/* <img src={money1} alt="" /> */}</center>
            <h5 className="text-center">Reject invitation </h5>
            <p className="text-center pt-2">
              <b>You really want to reject this invitation ?</b>
            </p>
            <center className="mt-4">
              <button
                className="cancel-btn mx-2"
                onClick={handleCloseRejectConfirmModal}
              >
                No
              </button>
              {/* <Link to="/login"> */}
              <button
                className="logout-btn mx-2"
                onClick={() => handleAcceptOrRejectInvite("2")}
              >
                Yes
              </button>
              {/* </Link> */}
            </center>
          </Box>
        </Modal>

        <Modal
          open={showMediaModal}
          onClose={handleCloseMediaModal}
          aria-labelledby="media-modal-title"
          aria-describedby="media-modal-description"
        >
          <Box sx={style} className="p-0 border-0 border-rduis">
            <img
              src={xmark}
              alt=""
              className="x-mark m-3 position-absolute right-0"
              onClick={handleCloseMediaModal}
            />
            {selectedMedia && (
              <>
                {selectedMedia.type === "image" ? (
                  <img
                    src={selectedMedia.value}
                    alt="Large Preview"
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  />
                ) : (
                  <video
                    controls
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  >
                    <source src={selectedMedia.value} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </>
            )}
          </Box>
        </Modal>

      </div>
    </div>
  );
}

export default Index;
