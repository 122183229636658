import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import image from '../../../Images/cat-five1.png';
import DeleteDraft from '../../../Images/delete-draft.png';
import AppliedRequestInner from "../AppliedRequests/AppliedRequestInner";
import { getRequests } from "../../../services/api/contractApi";
import Loaders from '../../Loaders/index'

function AppliedRequest() {
  const [open, setOpen] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [selectedReasonId, setSelectedReasonId] = useState('0');
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(true);

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);


  const handleGetDisputeReasonsList = async () => {
    try {
      let data = {}; // You can pass any necessary data here for the API call
      const response = await getRequests(data);
      console.log("getUserReasonList response is here -->", response);
      if (response.statusCode === 200) {
        setReasons(response?.data || []); // Assuming response.data is an array
      }
    } catch (error) {
      console.error("Error in getUserReasonList :", error);
      // Handle error notification here
    }
    finally {
      setLoading(false); // Set loading to false when data fetching is complete
    }
  };

  useEffect(() => {
    handleGetDisputeReasonsList();
  }, []);
  if (loading) {
    return <Loaders />; // Render the loader while loading is true
  }

  return (
    <div className="row px-3 row-top-margin">
      <div  className={` ' inner-shadow pb-5 theme-switcher ${theme === 'dark' ? 'inner-shadow-dark' : 'light-theme' }`}>
        <h3 className={`overview-heading-wallet text-center mt-4 mb-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>
          Applied Requests
        </h3>
        {reasons.length > 0 ? (
          <div className="row px-1 row-top-margin">
            {reasons.map((reason, index) => (
              <AppliedRequestInner key={index} reason={reason} />
            ))}
          </div>
        ) : (
          <p className='text-center pt-4'>No applied requests</p>
        )}
      </div>
    </div>
  );
  
}

export default AppliedRequest;
