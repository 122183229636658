import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from "../ChangePasswordModal";
import EditProfileModal from "../EditProfileModal";
import ChangePhoneModal from "../ChangePhoneModal";
import image from '../../../Images/transformed-(1) 1.png';

const Overview = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (

    <div className="container ">
      <div className="row px-3 row-top-margin p-5 pt-0">
      <div  className={` ' inner-shadow theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>


        <div
          className="side-bar-name-nav pb-2 mx-3 pt-2 text-center mb-5 mt-4"
          id="wallet-section-two"
        >
          <h3  className={` ' overview-heading-wallet theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>Contact us</h3>
          <center>
            <img src={image} alt="" />
          </center>
          <h3  className={` ' overview-heading mt-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>Mail Us</h3>
          <p  className={` ' mx-5 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>Write To Us On</p>
          <h5  className="overview-heading-h5 " >ideiosoft@gmail.com</h5>

          <center>
            <a href="mailto:ideiosoft@gmail.com">
              <button className="payment-btn mt-4">
                Contact us
              </button>
            </a>
          </center>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Overview;
