
import axiosInstance from '../axiosInstance';

export const getDraftNotesList = async () => {
    try {
        const response = await axiosInstance.get('/user/notes/notesDraftList');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addNote = async (credentials) => {
    try {
        const response = await axiosInstance.post('/user/notes/addNotes', credentials);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const editNote = async (credentials) => {
    try {
        const response = await axiosInstance.put('/user/notes/updateNotes', credentials);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteNote = async (id) => {
    try {
        const response = await axiosInstance.delete(`/user/notes/deleteDraft/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getDraftNoteDetails = async (id) => {
    try {
        const response = await axiosInstance.get(`/user/notes/notesDraftDetails/${id}`)
        return response.data;
    } catch (error) {
        throw error;
    }
};