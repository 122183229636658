import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from "../ChangePasswordModal";
import EditProfileModal from "../EditProfileModal";
import ChangePhoneModal from "../ChangePhoneModal";
import Added from '../../../Images/Added.png';
import withdrawal from '../../../Images/withdrawal.png';
import Chart from './chart';
import WithdrawAmountModal from './WithdrawAmountModal';
import fillter from '../../../Images/fillter.svg';
import { getWalletDetails } from "../../../services/api/walletApi";
import { notifyError, notifySuccess } from "../../../toastNotifications";
import Loaders from '../../Loaders/index'

function Overview() {

  
  const [loading, setLoading] = useState(false);
  const [walletDetails, setWalletDetails] = useState(null);
  const [walletBal, setWalletBal] = useState(0);

  
  const handleGetWalletDetails = async () => {
    setLoading(true)
    try {
      const response = await getWalletDetails();
      console.log("getWalletDetails response is here -->", response);
      if (response.statusCode === 200) {
        // notifySuccess(response.message);
        setWalletDetails(response.data.checkWallet)
        setWalletBal(response.data.checkWallet.ammount)
      }
    } catch (error) {
      console.error("Error in getWalletDetails :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`)

    }
    finally{setLoading(false)}
  }
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);


  useEffect(() => {
    handleGetWalletDetails();
  },[])

  if (loading) {
    return <Loaders />; // Render the loader while loading is true
  }

  return (
    <div className="row px-3 row-top-margin">
      <div  className={` ' inner-shadow theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>
        <div
          className="side-bar-name-nav pb-2 mx-3 pt-2  mb-5 mt-4"
          id="wallet-section-two"
        >
          <h3 className={` ' overview-heading-wallet text-center theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>My Earning</h3>
          <div className={` ' inner-shadow text-start p-3 mt-4  text-start theme-switcher ${theme === 'dark' ? 'dark-theme' : 'light-theme' }`}>
            <p className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>Wallet Balance</p>
            <h3 className={` ' overview-heading-wallet text-start theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>{`$${walletBal}`}</h3>
            <WithdrawAmountModal handleGetWalletDetails={handleGetWalletDetails}/>
          </div>
          
          <Chart />
        </div>
      </div>
    </div>
  );
}

export default Overview;
