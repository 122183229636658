export const getStartOfWeekSunday = (date) => {
    const today = date ? new Date(date) : new Date();
    const day = today.getDay(); // Get current day of the week (0 - Sunday, 1 - Monday, ..., 6 - Saturday)
    const diff = today.getDate() - day  // Calculate the difference to Sunday
    const startOfWeek = new Date(today.setDate(diff));
    // Format the date to 'YYYY-MM-DD' format
    const formattedDate = startOfWeek.toISOString().split('T')[0];
    return formattedDate;
}


export const getNextWeekStartDate = (date) => {
    const nextWeekStartDate = new Date(date);
    nextWeekStartDate.setDate(nextWeekStartDate.getDate() + 7); // Add 7 days to get next week's start date
    return getStartOfWeekSunday(nextWeekStartDate);
}

export const getPreviousWeekStartDate = (date) => {
    const prevWeekStartDate = new Date(date);
    prevWeekStartDate.setDate(prevWeekStartDate.getDate() - 7); // Subtract 7 days to get previous week's start date
    return getStartOfWeekSunday(prevWeekStartDate);
}

export const getWeekEndDate = (date) => {
    const weekEndDate = new Date(date);
    weekEndDate.setDate(weekEndDate.getDate() + 6); // Add 7 days to get next week's start date
    const formattedDate = new Date(weekEndDate).toISOString().split('T')[0];
    return formattedDate;
}

export const formatDate = (val) => {
    const date = new Date(val)
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // Get day, month, and year from the date object
    const day = date.getDate().toString().padStart(2, '0'); // Ensures two-digit day
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}



export const formatDateAndTime = (dateString) => {
    const date = new Date(dateString);

    // Months array to convert month index to month name
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    //  [
    //     'January', 'February', 'March', 'April', 'May', 'June',
    //     'July', 'August', 'September', 'October', 'November', 'December'
    // ];

    // Get day, month, year, hours, and minutes from the date object
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format the date string in the desired format
    const formattedDate = `${day} ${months[monthIndex]} ${year}, ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

    return formattedDate;
}