import React, { useState, useEffect } from 'react';
import logo from '../../../Images/logo-mini.png';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../../services';
import { notifyError, notifySuccess } from '../../../toastNotifications';
import NavbarSignFlow from '../../Login/NavbarSignFlow'

function Index() {
  const navigate = useNavigate();
  const emailRegex = /^[^\s@]+@[^\s@]+\.(?:com|in|net|org|edu|gov|co\.uk)$/;

  const [emailOrPhone, setEmailOrPhone] = useState("");

  const handleForgotPassword = async () => {
    if (!emailOrPhone || emailOrPhone.trim() === "") {
      // notifyError(`Email or phone number required`)
      notifyError(`Email required`)
    } else if (!emailRegex.test(emailOrPhone)) {
      notifyError(`Invalid email`)
    } else {
      let data = {
        emailOrPhone
      }
      try {
        const response = await forgotPassword(data);
        console.log("forgotPassword response is here -->", response);
        if (response.statusCode === 200) {
          notifySuccess(response.message);
          navigate(`/otp-verification-mail/${emailOrPhone}`)
        }
      } catch (error) {
        console.error("Error in forgotPassword :", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
      }
    }
  }
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div>
      <NavbarSignFlow/>
      <div className={` ' container-fluid theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`} >
        <div className="row">
          <div className="col-md-7 login-left-section d-flex px-5">
            <div className="justify-content-center align-self-center">
              <h2>International ask Question with Ask Human!</h2>
              <p>Don’t worry! It occurs. Please enter the email address linked with your account</p>
            </div>
          </div>
          <div className={` ' col-md-5 login-right-section m-auto theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            {/* <p className='pt-4 px-4 login-right-section-p'>
              Don’t have an account?
              <span>
                <b><Link to="/sign-up" className={` ' forgot-password text-dark theme-switcher ${theme === 'dark' ? 'dark-mode-pink-color' : 'light-theme'}`}>Signup</Link></b>
              </span>
            </p> */}
            <div className="ask-to-sign login-right-section-flex px-5 ">
              <h2 className='d-flex'>Forgot Password <img src={logo} alt="" /></h2>
              <Form.Label htmlFor="inputPassword5" className={` ' form-label mt-5 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`} >Email</Form.Label>
              {/* <Form.Control
                type="email"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className={` ' form-input mt-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}
                placeholder='Input Your Number / Email Here'
                value={emailOrPhone}
                onChange={(e) => setEmailOrPhone(e.target.value)}
              /> */}
              <Form.Control
                type="email"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className={`form-input mt-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                placeholder='Input Your Email Here'
                value={emailOrPhone}
                onChange={(e) => setEmailOrPhone(e.target.value)}
              />
              <button className='sign-in-btn mt-4' onClick={handleForgotPassword}>Send Code</button>
              {/* <button className='sign-in-btn mt-4' id="hello" onClick={(e) => console.log(e.target.id)}>Get OTP</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
