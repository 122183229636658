import React, { useEffect, useState } from "react";
import image from '../../../Images/cat-five1.png';
import DeleteDraft from '../../../Images/delete-draft.png';
import { deleteNote, getDraftNotesList } from "../../../services/api/notesApi";
import { notifyError, notifySuccess } from "../../../toastNotifications";
import { useNavigate } from "react-router-dom";
import xmark from '../../../Images/x-mark.png';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import NoMore from '../../../Images/no-da.svg'
import { getWalletDetails } from "../../../services/api/walletApi";
import logo from '../../../Images/logo-mini.png';
import Loaders from '../../Loaders/index'

function Overview() {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [draftNotes, setDraftNotes] = useState([]);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [open, setOpen] = useState(false);
  const [noteId, setNoteId] = useState('');
  const [showAmountCheckModal, setShowAmountCheckModal] = useState(false);
  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);

  const handleOpen = (val) => {
    setOpen(true);
    setNoteId(val);
  };
  const handleClose = () => {
    setOpen(false);
    setNoteId('');
  };

  const handleOpenAmountCheckModal = () => setShowAmountCheckModal(true);
  const handleCloseAmountCheckModal = () => setShowAmountCheckModal(false);

  const handleGetDraftNotes = async () => {
    try {
      const response = await getDraftNotesList();
      console.log("getDraftNotesList response is here -->", response);
      if (response.statusCode === 200) {
        setDraftNotes(response.data.draftList);
      }
    } catch (error) {
      console.error("Error in getDraftNotesList :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    }
    finally {
      setLoading(false); // Set loading to false when data fetching is complete
    }
  };

  const draftDeleteHandler = async () => {
    try {
      const response = await deleteNote(noteId);
      console.log("deleteNote response is here -->", response);
      if (response.statusCode === 200) {
        notifySuccess(response.message);
        handleGetDraftNotes();
      }
    } catch (error) {
      console.error("Error in deleteNote :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    } finally {
      handleClose();
    }
  };

  const renderMedia = (mediaUrl) => {
    const isVideo = mediaUrl.match(/\.(mp4|webm|ogg)$/i);
    return isVideo ? (
      <video 
        controls 
        style={{ borderRadius: '10px', width: '100%', height: '100%' }} 
        onClick={() => handleMediaClick(mediaUrl, 'video')}
      >
        <source src={mediaUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    ) : (
      <img 
        src={mediaUrl} 
        alt="Preview" 
        className='object-fit-cover' 
        style={{ borderRadius: '10px', width: '100%', height: '100%' }} 
        onClick={() => handleMediaClick(mediaUrl, 'image')}
      />
    );
  };

  const handleMediaClick = (mediaUrl, mediaType) => {
    setSelectedMedia({ url: mediaUrl, type: mediaType });
    setMediaModalOpen(true);
  };

  const handleMediaModalClose = () => {
    setMediaModalOpen(false);
    setSelectedMedia(null);
  };

  const handleSearch = async (id) => {
    try {
      const response = await getWalletDetails();
      console.log("getWalletDetails response is here -->", response);
      if (response?.statusCode === 200) {
        if (!response?.data?.checkWallet?.ammount || (response?.data?.checkWallet?.ammount && response?.data?.checkWallet?.ammount < 1)) {
          handleOpenAmountCheckModal();
        } else {
          navigate(`/user-list/${id}`);
        }
      }
    } catch (error) {
      console.error("Error in getWalletDetails :", error);
      notifyError(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    handleGetDraftNotes();
  }, []);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);
  if (loading) {
    return <Loaders />; // Render the loader while loading is true
  }

  return (
    <div className="row px-3 row-top-margin">
      <div className={`inner-shadow pb-5 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
        <h3 className={`overview-heading-wallet text-center mt-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          Draft list
        </h3>
        {draftNotes && draftNotes.length > 0
          ? (
            <>
              {draftNotes.map((val, index) => {
                let id = val?._id || null;
                let note = val?.note || null;
                let img = val?.media || null;
                return (
                  <div className="draft-driver p-4 mt-4" key={index}>
                    <div onClick={() => handleOpen(id)}>
                      <img src={DeleteDraft} alt="" className="delete-draft" />
                    </div>
                    <p>{note}</p>
                    <div className='d-flex flex-wrap justify-content-start align-items-center mt-3'>
                      {img && img.length > 0
                        ? img.map((mediaUrl, index) => (
                          <div className='m-2' style={{ width: '8rem', height: '7rem', position: 'relative' }} key={index} >
                            {renderMedia(mediaUrl)}
                          </div>
                        ))
                        : null
                      }
                    </div>
                    <button className="pay-now-btn mt-4 border-1 new-dev" onClick={() => navigate(`/search-note/${id}`)}>Edit</button>
                    <button className="payment-btn mt-4 float-right" onClick={() => handleSearch(id)}>
                      Search
                    </button>
                  </div>
                )
              })}
            </>
          )
          : <img src={NoMore} alt="" className="no-da mb-4" />
        }
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={`p-0 border-0 border-rduis p-4 ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
            <img src={xmark} alt="" className='x-mark' onClick={handleClose} />
            <center>
            </center>
            <h5 className='text-center'>Delete Note </h5>
            <p className='text-center pt-2'><b> Are you sure, you really want to delete this note ?</b></p>
            <center className='mt-4'>
              <button className='cancel-btn mx-2' onClick={handleClose}>No</button>
              <button
                className='logout-btn mx-2'
                onClick={draftDeleteHandler}
              >
                Yes
              </button>
            </center>
          </Box>
        </Modal>
      </div>

      <div>
        <Modal
          open={showAmountCheckModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={`inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
            <div className="d-grid">
              <div className="d-flex justify-content-center">
                <img src={logo} alt="" className="h-75" />
              </div>
              <p className={`text-center py-2 px-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                You have insufficient funds for your search. First Add funds to continue.
              </p>
            </div>
            <center className='mt-4'>
              <button className='cancel-btn mx-2' onClick={handleCloseAmountCheckModal}>Cancel</button>
              <button className='logout-btn mx-2' onClick={() => document.getElementById("vertical-tab-1").click()}>Add payment</button>
            </center>
          </Box>
        </Modal>
      </div>

      <div>
        <Modal
          open={mediaModalOpen}
          onClose={handleMediaModalClose}
          aria-labelledby="media-modal-title"
          aria-describedby="media-modal-description"
        >
          <Box sx={style} className={`p-0 border-0 border-rduis p-4 ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
            <img src={xmark} alt="" className='x-mark float-right' onClick={handleMediaModalClose} style={{position:'absolute', right:"10px", top:'10px'}} />
            {selectedMedia && selectedMedia.type === 'video' ? (
              <video controls style={{ width: '100%', maxHeight:'100vh' }}>
                <source src={selectedMedia.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              selectedMedia && <img src={selectedMedia.url} alt="Large Preview" style={{ width: '100%', maxHeight:'100vh' }} />
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default Overview;
