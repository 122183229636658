import React, { useState, useEffect } from 'react';
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import FilterListIcon from "@mui/icons-material/FilterList";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function CustomizedMenus({ setIbFilter }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState("all");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value) => () => {
    setSelectedValue(value);
    setAnchorEl(null);
    let labelId = getLabelId(value);
    console.log("labelId", labelId)
    setIbFilter(labelId);
    localStorage.setItem("ibFilter", labelId)
  };

  const getLabel = (value) => {
    switch (value) {
      case "all":
        return "All";
      case "favorite":
        return "Favorite";
      case "unread":
        return "Unread Messages";
      case "accepted":
        return "Accepted Proposal";
      case "rejected":
        return "Rejected Proposal";
      case "ongoing":
        return "Ongoing Contracts";
      case "complete":
        return "Complete Contracts";
      default:
        return "";
    }
  };

  const getLabelId = (value) => {
    switch (value) {
      case "all":
        return "";
      case "favorite":
        return "0";
      case "unread":
        return "5";
      case "accepted":
        return "1";
      case "rejected":
        return "2";
      case "ongoing":
        return "1";
      case "complete":
        return "3";
      default:
        return "";
    }
  };

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);


  return (
    <div>
      <TextField
        fullWidth
        value={getLabel(selectedValue)}
        InputProps={{
          

          readOnly: true,
          endAdornment: (
            <Button
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <FilterListIcon className={`theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`} />
            </Button>
          ),
          
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
          },
        }}
      />
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="dark-theme-only-white"
      >
        <div className={`theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          <MenuItem onClick={handleMenuItemClick("all")} disableRipple >
            <ListItemIcon>
              <Checkbox checked={selectedValue === "all"} />
            </ListItemIcon>
            <span className="mt-2" >All</span>
          </MenuItem>
          <MenuItem onClick={handleMenuItemClick("favorite")} disableRipple>
            <ListItemIcon>
              <Checkbox checked={selectedValue === "favorite"} />
            </ListItemIcon>
            <span className="mt-2">Favorite</span>
          </MenuItem>
          <MenuItem onClick={handleMenuItemClick("unread")} disableRipple>
            <ListItemIcon>
              <Checkbox checked={selectedValue === "unread"} />
            </ListItemIcon>
            <span className="mt-2">Unread Messages</span>
          </MenuItem>
          {/* <MenuItem onClick={handleMenuItemClick("accepted")} disableRipple>
            <ListItemIcon>
              <Checkbox checked={selectedValue === "accepted"} />
            </ListItemIcon>
            <span className="mt-2">Accepted Proposal</span>
          </MenuItem> */}
          <MenuItem onClick={handleMenuItemClick("rejected")} disableRipple>
            <ListItemIcon>
              <Checkbox checked={selectedValue === "rejected"} />
            </ListItemIcon>
            <span className="mt-2">Rejected Proposal</span>
          </MenuItem>
          <MenuItem onClick={handleMenuItemClick("ongoing")} disableRipple>
            <ListItemIcon>
              <Checkbox checked={selectedValue === "ongoing"} />
            </ListItemIcon>
            <span className="mt-2">Ongoing Contracts</span>
          </MenuItem>
          <MenuItem onClick={handleMenuItemClick("complete")} disableRipple>
            <ListItemIcon>
              <Checkbox checked={selectedValue === "complete"} />
            </ListItemIcon>
            <span className="mt-2">Complete Contracts</span>
          </MenuItem>
        </div>
      </StyledMenu>
    </div>
  );
}