import axiosInstance from '../axiosInstance';

export const getMyNotifications = async (data) => {
    const { offset, limit } = data
    try {
        const response = await axiosInstance.get(`/user/notification/myNotification?offset=${offset}&limit=${limit}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const getMessageId = async (data) => {
    try {
        const response = await axiosInstance.get(`/message/getMessageId?notificationId=${data}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
