import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import xmark from '../../../Images/x-mark.png';
import { addDispute, getUserReasonList } from '../../../services';
import { notifyError, notifySuccess } from '../../../toastNotifications';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ msgId, handleCreateDisputeMessage }) {
  const [open, setOpen] = React.useState(false);
  const [reasons, setReasons] = useState([]);
  const [selectedReasonId, setSelectedReasonId] = useState('0');
  const [comment, setComment] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleGetDisputeReasonsList = async () => {
    try {
      const response = await getUserReasonList();
      console.log("getUserReasonList response is here -->", response);
      if (response.statusCode === 200) {
        // notifySuccess(response.message);
        setReasons(response?.data?.reasonList || []);
      }
    } catch (error) {
      console.error("Error in getUserReasonList :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`)

    }
  };

  const handleAddDispute = async () => {
    if (selectedReasonId === "0") {
      notifyError(`Please select a reason`)
    } else {
      let data = {
        reasonId: selectedReasonId,
        messageId: msgId,
        comment: !comment || comment.trim() == "" ? null : comment.trim()
      }
      try {
        const response = await addDispute(data);
        console.log("addDispute response is here -->", response);
        if (response.statusCode === 200) {
          notifySuccess(response.message);
          handleCreateDisputeMessage();
        }
      } catch (error) {
        console.error("Error in addDispute :", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
      } finally {
        handleClose();
        setSelectedReasonId('0');
        setComment('');
      }
    }
  }

  React.useEffect(() => {
    if (open) {
      handleGetDisputeReasonsList();
    }
  }, [open]);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);


  return (
    <div>
      <button onClick={handleOpen} className='px-3 pb-2 pt-2'>Add Dispute</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
          }`} >
          <img src={xmark} alt="" className='x-mark' onClick={handleClose} /> {/* Add onClick handler here */}
          <h5 className='text-center'>Add Dispute </h5>
          <Form.Label htmlFor="inputPasswordOld" className={` ' form-label-dark mt-2 theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
            }`}>Reason for dispute</Form.Label> <br />
          <Form.Select aria-label="Default select example" className={` ' form-input-modal theme-switcher ${theme === "dark" ? "dark-theme-only-color " : "light-theme"
            }`}
            value={selectedReasonId} onChange={(e) => setSelectedReasonId(e.target.value)}>
            <option value={'0'}>Select a reason</option>
            {reasons && reasons.map((val, index) => {
              return <option value={val?._id} key={index} >{val?.reason}</option>;
            })}
          </Form.Select>
          <Form.Label htmlFor="inputPasswordOld"
            className={` ' form-label-dark mt-3 theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
              }`}>
            Comment
          </Form.Label>
          <div className="password-input-container">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              style={{ height: '100px' }}
              maxLength={300}
              className={` ' form-input-modal theme-switcher ${theme === "dark" ? "dark-theme-only-color " : "light-theme"
                }`}
            />
            <button className="search-btn w-100 mt-3" onClick={handleAddDispute}>
              Add Dispute
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
