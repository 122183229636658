import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import xmark from "../../Images/x-mark.png";
import LightBulb from "../../Images/3d-render-hand-with-light-bulb-creative-idea.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ modelOpenStatus }) {
  const [open, setOpen] = React.useState(modelOpenStatus);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleButtonClick = () => {
    handleClose();
    window.location.href = "/home";
  };
  console.log("open", open)
  console.log("modelOpenStatus", modelOpenStatus)

  	
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="p-0 border-0 border-rduis p-4">
          <img src={xmark} alt="Close" className="x-mark" onClick={handleClose} />
          <center>
            <img src={LightBulb} alt="Light Bulb" />
          </center>
          <h5 className="text-center">Welcome to Ask Human</h5>
          <p className="text-center pt-2">
            Congratulations on your First step towards Getting your answers using chat.
          </p>
          <center className="mt-4">
            {/* <Link to="/home"> */}
              <button className="logout-btn mx-2 w-100" onClick={handleButtonClick}>
                Go to Home
              </button>
            {/* </Link> */}
          </center>
        </Box>
      </Modal>
    </div>
  );
}
