import * as React from 'react';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const CustomSlider = styled(Slider)({
  color: '#F10B80',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#F10B80',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#F10B80',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export default function CustomizedSlider({ value, onChange }) {
  const [val, setVal] = React.useState(value || 0);

  const handleChange = (event, newValue) => {
    setVal(newValue);
    onChange(newValue);
  };

  return (
    <Box>
      {/* <Typography gutterBottom>Select Age</Typography> */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ minWidth: 35 }}>18</Typography>
        <CustomSlider
          value={parseInt(val)}
          onChange={handleChange}
          // valueLabelDisplay="on"
          aria-label="Select Age"
          min={18}
          max={72}
        />&nbsp;&nbsp;&nbsp;&nbsp;
        <Typography>{72}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

      </Box>
    </Box>
  );
}
