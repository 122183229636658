
import axiosInstance from '../axiosInstance';

export const inviteSingleUser = async (data) => {
    try {
        const response = await axiosInstance.post('/message/sendInvitation', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const inviteMultipleUsers = async (data) => {
    try {
        const response = await axiosInstance.post('/message/sendMultipleInvitation', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const acceptOrRejectOrEndInvitation = async (data) => {
    try {
        const response = await axiosInstance.put('/message/acceptOrRejectOrEndInvitation', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const referInvitatationAcceptReject = async (data) => {
    const { notesId, userId, status } = data;
    try {
        const response = await axiosInstance.put(`/message/referInviteAcceptReject/${notesId}/${userId}/${status}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const invitationDetails = async (data) => {
    try {
        const response = await axiosInstance.post('/message/invitationDetails', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getContractList = async (data) => {
    const { offset, limit, filterStatus } = data;
    try {
        const response = await axiosInstance.get(`/message/contractList?offset=${offset}&limit=${limit}&filterStatus=${filterStatus}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// export const getContractDetails = async (data) => {
//     // const { offset, limit } = data;
//     console.log(data,"datadatadatadatadata")
//     try {
//         const response = await axiosInstance.get(`/message/invitationDetails,${data._id}`);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

export const getMyContractList = async (data) => {
    const { offset, limit, filterStatus } = data;
    try {
        const response = await axiosInstance.get(`/message/mycontractList?offset=${offset}&limit=${limit}&filterStatus=${filterStatus}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const getRequests = async (data) => {
    const { offset, limit } = data;
    try {
        const response = await axiosInstance.get(`/message/getRequests?offset=${1}&limit=${10000}`);
        console.log(response, "responseresponseresponseresponse")
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const continueChat = async (data) => {
    try {
        const response = await axiosInstance.post('/message/continueChat', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const referInvitation = async (data) => {
    try {
        const response = await axiosInstance.post('/message/referInvitation', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const applyInvitation = async (data) => {
    try {
        const response = await axiosInstance.post('/message/applyInvitation', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const bookMarkOrDeleteMessage = async (data) => {
    const { messageId, type } = data;
    try {
        const response = await axiosInstance.put(`/message/bookmark/${messageId}/${type}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};