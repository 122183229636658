import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Footer from "../../Footer";

const FAQAccordion = () => {
  return (
    <div>
        <div className="main-header-section mt-4">
            <h1>Frequently Asked Questions</h1>
        </div>
    <div className="container mt-5 pb-2">
    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <strong>1. What is Ask Human?</strong>
        </AccordionSummary>
        <AccordionDetails>
          Ask Human is a platform that connects users with real human experts who provide personalized answers to questions across various topics.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <strong>2. How do I create an account?</strong>
        </AccordionSummary>
        <AccordionDetails>
          Simply sign up on our website with your email address or through social media accounts. Follow the prompts to complete your profile.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <strong>3. Can I ask any type of question?</strong>
        </AccordionSummary>
        <AccordionDetails>
          Yes, you can ask questions on a wide range of topics. Our experts cover diverse fields to provide the information you need.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          <strong>4. How do I find an expert?</strong>
        </AccordionSummary>
        <AccordionDetails>
          Browse through our list of experts or use the search function to find someone with expertise in the area you're interested in.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel5-content"
          id="panel5-header"
        >
          <strong>5. How do I invite an expert to chat?</strong>
        </AccordionSummary>
        <AccordionDetails>
          Select an expert and send them an invitation to chat. You can also provide a brief description of your query to help them prepare.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel6-content"
          id="panel6-header"
        >
          <strong>6. What happens if an expert accepts my invitation?</strong>
        </AccordionSummary>
        <AccordionDetails>
          You will pay for the chat on an hourly basis. The expert will then provide you with their insights and answers.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel7-content"
          id="panel7-header"
        >
          <strong>7. How do I get paid for answering questions?</strong>
        </AccordionSummary>
        <AccordionDetails>
          If you accept an invitation to answer a question, you will be compensated based on the duration and complexity of the interaction.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel8-content"
          id="panel8-header"
        >
          <strong>8. What if no one accepts my invitation?</strong>
        </AccordionSummary>
        <AccordionDetails>
          If your invitation is not accepted, your $1 invitation fee will be refunded.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel9-content"
          id="panel9-header"
        >
          <strong>9. Can I dispute a payment if I’m not satisfied with the answer?</strong>
        </AccordionSummary>
        <AccordionDetails>
          Yes, you can dispute a payment if you are not satisfied with the answer. Please follow our dispute process for a refund.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel10-content"
          id="panel10-header"
        >
          <strong>10. How can I provide feedback?</strong>
        </AccordionSummary>
        <AccordionDetails>
          After your interaction, you can leave feedback for the expert or the person who asked the question through our feedback system.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel11-content"
          id="panel11-header"
        >
          <strong>11. What if I’m invited but not available?</strong>
        </AccordionSummary>
        <AccordionDetails>
          You can refer the invitation to someone else who may be interested in providing the answer. They can accept the invitation on your behalf.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel12-content"
          id="panel12-header"
        >
          <strong>12. How do I update my profile information?</strong>
        </AccordionSummary>
        <AccordionDetails>
          Log in to your account and navigate to the profile settings to update your information as needed.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel13-content"
          id="panel13-header"
        >
          <strong>13. Are there any hidden fees?</strong>
        </AccordionSummary>
        <AccordionDetails>
          No, we maintain transparency with our pricing. You will only be charged for the services you use, with no hidden fees.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel14-content"
          id="panel14-header"
        >
          <strong>14. Can I cancel a chat session?</strong>
        </AccordionSummary>
        <AccordionDetails>
          Cancellations are subject to our cancellation policy. Please review the terms before scheduling a session.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="customIcon" />}
          aria-controls="panel15-content"
          id="panel15-header"
        >
          <strong>15. How do I contact customer support?</strong>
        </AccordionSummary>
        <AccordionDetails>
          You can reach our customer support team through the contact form on our website or via email. We are here to help.
        </AccordionDetails>
      </Accordion>
    </div>
    <Footer />
    </div>
  );
};

export default FAQAccordion;
