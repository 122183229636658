import React, { useRef, useState, useEffect } from 'react';
import useUserMedia from './useUserMedia'; // Assuming you have a custom hook for accessing user media
import { videoUpload } from '../services';
import { notifyError, notifySuccess } from '../toastNotifications';

const CAPTURE_OPTIONS = {
    video: true
};

const VideoRecorder = ({ handleClose }) => {
    const videoRef = useRef();
    const recorderRef = useRef(); // Initialize with null
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [countdown, setCountdown] = useState(15);
    // const [videoUrl, setVideoUrl] = useState(null);
    const stream = useUserMedia(CAPTURE_OPTIONS);

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);

    // console.log("stream", stream)

    const onCanPlay = () => {
        videoRef.current.play();
    };

    const onStartCapture = () => {
        // setVideoUrl(null)
        setRecordedChunks([]);
        console.log("recorderRef.current", recorderRef.current);
        // recorderRef.current = null
        if (stream) { // Check if stream is available and recorderRef is not yet initialized
            recorderRef.current = new MediaRecorder(stream);
            recorderRef.current.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    setRecordedChunks(prev => [...prev, e.data]);
                }
            };
            recorderRef.current.onerror = (e) => {
                console.error('Error while recording:', e.error);
            };
            recorderRef.current.start();
            setCapturing(true);
        }
    };

    const onStopCapture = () => {
        // if (recorderRef.current && capturing) {
        if (capturing) {
            recorderRef.current.stop();
            setCapturing(false);
        }
    };

    const uploadVideo = () => {
        console.log("recordedChunks", recordedChunks)
        if (recordedChunks.length > 0 && !capturing && !uploading) {
            setUploading(true);
            const file = new File(recordedChunks,
                `${JSON.parse(localStorage.getItem("user"))._id}.mp4`, { type: 'video/mp4' });

            const reader = new FileReader();
            reader.onloadend = async () => {
                const dataURL = reader.result;
                const byteString = atob(dataURL.split(",")[1]);
                const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                const blob = new Blob([ab], { type: mimeString });
                const formData = new FormData();
                formData.append("video", blob, file.name);
                try {
                    const videoUploadRes = await videoUpload(formData);
                    console.log(videoUploadRes, "videoUploadRes")
                    if (videoUploadRes.statusCode === 200) {
                        notifySuccess(videoUploadRes.message)
                    }
                } catch (error) {
                    console.error("Error uploading image:", error);
                    notifyError(error?.response?.data?.message || `Something went wrong`)
                } finally {
                    handeCloseModal()
                }

            };
            // Read the file as a binary string
            reader.readAsDataURL(file);
        }
    }

    const handeCloseModal = () => {
        videoRef.current = "";
        recorderRef.current = "";
        setCapturing(false);
        setRecordedChunks([]);
        setUploading(false);
        setCountdown(15)
        handleClose();
    }

    useEffect(() => {
        if (capturing) {
            if (countdown === 0) {
                onStopCapture();
                return
            };
            const timer = setInterval(() => {
                setCountdown(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [countdown, capturing]);


    // useEffect(() => {
    //     if (recordedChunks && recordedChunks.length > 0 && !capturing) {
    //         setVideoUrl(
    //             URL.createObjectURL(
    //                 new Blob(recordedChunks, { type: 'video/webm' })
    //             )
    //         )
    //     }
    // }, [capturing, recordedChunks, setVideoUrl]);


    // console.log("videoUrl", videoUrl)

    return (
        <>
            {stream &&
                (<p className='text-center'>
                    Align your face in the center of the circle , Video Size is maximum 15 Sec only
                </p>)}
            <div className="mt-2">
                {stream == null
                    ?
                    <div className='text-center py-4'>
                        <span>
                            Detecting camera...
                        </span>
                    </div>
                    :
                    stream == false
                        ?
                        <div className='text-center py-4'>
                            <span>
                                Your browser does not support / allow the video recording.
                            </span>
                        </div>
                        // :
                        // videoUrl
                        //     ?
                        //     <video style={{
                        //         height: "16rem",
                        //         width: "23rem"
                        //     }} controls src={videoUrl} />
                        : <video style={{
                            height: "16rem",
                            width: "23rem"
                        }} ref={videoRef} onCanPlay={onCanPlay} autoPlay playsInline muted />
                }
            </div >

            {capturing
                ? <p className={`text-center mt-2`}>
                    Auto stops in  <span className='text-danger'>{countdown}</span> {countdown > 1 ? `Seconds` : `Second`}
                </p>
                : ""}
            <center className="mt-4">
                {stream
                    ? capturing
                        ?
                        <button className="logout-btn w-100"
                            onClick={onStopCapture}
                        >Stop Recording</button>
                        : recordedChunks && recordedChunks.length > 0
                            ?
                            <div className="d-flex gap-4">
                                <button
                                    className={`stop-btn`}
                                    onClick={handeCloseModal}
                                >Cancel</button>
                                {uploading
                                    ? <button className="logout-btn w-50"
                                    >Uploading...</button>
                                    : <button className="logout-btn px-5 py-2 w-50"
                                        onClick={uploadVideo}
                                    >Upload</button>}
                            </div>
                            : <button className="logout-btn w-100"
                                onClick={onStartCapture}
                            >Start Recording</button>
                    : ''}
            </center>
        </>
    );
};

export default VideoRecorder;